import styled from 'styled-components';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const Container = styled.div`
  display: ${({ open }) => open ? 'block' : 'none'};
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;

    label {
      width: 100%;
      max-width: 365px;
      font-size: 12px;

      p {
        height: 16px;
        padding-left: 10px;
      }
    }
    input:-internal-autofill-selected {
      background-color: transparent !important;
    }
    input {
      width: 100%;
      margin-bottom: 40px;
      padding: 17px 10px;
      color: ${COLORS.white};
      border-bottom: 1px solid ${COLORS.white};
      font-weight: 800;
      font-size: 16px;

      &::placeholder {
        color: ${COLORS.white};
        font-weight: 400;
      }
    }

    label.error {
      input {
        border-color: ${COLORS.red};

        &::placeholder {
          font-weight: 800;
          color: ${COLORS.red};
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    button {
      position: relative;
      right: 38px;
      width: 274px;
      height: 48px;
      margin-top: 23px;
      background: ${COLORS.whiteBlue};
      color: ${COLORS.white};
      border-radius: 25px;
      font-weight: 800;
    }

    .msg-alert {
      margin-top: 10px;
      margin-right: 38px;
    }

    @media screen and (min-width: ${global.webSize}){
      align-items: center;
      margin-left: 55px;

      button {
        right: 0;
      }

      .msg-alert {
        margin-right: 0;
      }
    }

    @media screen and (max-width: ${global.mobileSizeM}){
      label { 
        width: 328px;
      }

      label[for="phone"] {
        width: 311px;
      }

      label[for="email"] {
        width: 285px;
      }

      button {
        width: 274px;
        right: 0;
        font-size: 18px;
      }

      .msg-alert {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: ${global.mobileSizeS}){
      padding: 0 15px;

      label { 
        width: 280px;
      }

      label[for="phone"] {
        width: 260px;
      }

      label[for="email"] {
        width: 240px;
      }

      button {
        width: 210px;
      }
    }
  }

  & > button.option {
    color: ${COLORS.whiteBlue};
    position: absolute;
    top: 60px;
    left: 80px;
    font-weight: 800;

    @media screen and (max-width: ${global.webSize}){
      top: 55px;
    }

    @media screen and (max-width: ${global.tabletSize}){
      top: 45px;
    }

    @media screen and (max-width: ${global.mobileSize}){
      left: 40px;
    }

    @media screen and (max-width: ${global.mobileSizeM}){
      top: 25px;
      left: 30px;
    }
  }

  .form-title {
    display: none;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 63px;

		svg {
			margin-right: 25px;
		}

    @media screen and (max-width: ${global.tabletSize}){
      display: block;
      text-align: center;
		  margin: 20px 0 40px 80px;
	  }

    @media screen and (max-width: ${global.mobileSizeM}){
      text-align: left;
      margin-left: 40px;
    }

    @media screen and (max-width: ${global.mobileSizeS}){
      margin: 55px 0 35px 40px;
    }
	}
`;

export const MessageContainer = styled.div`
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  text-align: center;

  h3 {
    margin: 32px 0 10px 0;
    font-family: 'Avenir-black';
    font-size: 36px;
    background: -webkit-linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.28) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.light {
    h3 {
      background: -webkit-linear-gradient(270deg, #010103 0%, rgba(1, 1, 3, 0.49) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
