import styled from 'styled-components';
import * as global from '@styles/global.style';

export const FooterContainer = styled.footer`
  display: none;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 15vh;
  padding-bottom: 20px;
  box-shadow: inset 0px 4px 20px rgba(0, 254, 255, 0.1);

  p {
    font-size: 16px;
  }

  svg {
    position: absolute;
    right: 0;
    top: -80px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 10%;
    width: 80%;
    height: 100px;
    border-radius: 10px;
    background: #031CFF;
    filter: blur(60px);
    opacity: 0.1;
  }

  @media screen and (max-width: ${global.mobileSize}){
    height: 20vh;
  }
`;
