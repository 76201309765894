import styled, { createGlobalStyle } from 'styled-components';
import pilotDarkPng from '@assets/images/pilotDark.png';
import pilotLightPng from '@assets/images/pilotLight.png';
import pilotDarkMobilePng from '@assets/images/pilotDarkMobile.png';
import pilotLightMobilePng from '@assets/images/pilotLightMobile.png';
import * as global from '@styles/global.style';

export const GlobalStyle = createGlobalStyle`
  .app {

    .wrapper {
      min-height: 90vh;

      &::after {
        content: url(${pilotDarkPng});
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    footer {
      display: none;
    }

    &.light {
      .wrapper {
        &::after {
          content: url(${pilotLightPng});
        }
      }
    }

    @media screen and (max-width: ${global.mobileSize}){
      background-position: center;

      .wrapper {
        &::after {
          content: url(${pilotDarkMobilePng});
        }
      }

      &.light {
        .wrapper {
          &::after {
            content: url(${pilotLightMobilePng});
          }
        }
      }
    }
  }
`;

export const ContactUsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 90px;

  @media screen and (max-width: ${global.mobileSize}){
    padding-top: 50px;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const TextContent = styled.div`
  position: relative;
  z-index: 1;

  h1 {
    font-family: 'Avenir-Black' !important;
    font-size: 36px;
    margin-bottom: 110px;
    background: ${({theme}) => theme === "dark" ?
    '-webkit-linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.28) 100%)'
      :
    '-webkit-linear-gradient(180deg, #010103 0%, rgba(1, 1, 3, 0.49) 100%)'
    };
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 22px;
    margin-bottom: 50px;
  }

  span.bold {
    font-weight: 800;
  }

  button {
    margin-top: 40px;
    ${global.ButtonStyle};
  }

  @media screen and (max-width: ${global.mobileSize}){
    text-align: center;
    padding-left: 0;

    h1 {
      font-size: 26px;
      margin-bottom: 80px;
    }

    p {
      font-size: 20px;
      margin-bottom: 40px;
    }

    button {
      margin: 30px 0 110px 0;
    }
  }
`;
