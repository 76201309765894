import styled from 'styled-components';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: ${(props) => props.open ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  background: rgba(1, 1, 3, 0.7);
  backdrop-filter: blur(4px);

  &.light {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);
  }

  @media screen and (max-width: ${global.mobileSize}){
    background: rgba(0, 0, 0, 0.2);

    &.light {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 480px;
  padding: 53px 0;
  background: ${COLORS.darkTransparent};
  box-shadow: 0px 4px 20px ${COLORS.boxShadow};
  backdrop-filter: blur(10px);

  h3 {
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 42px;
    background: -webkit-linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.28) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.dark {
    button.dark {
      font-weight: 800;
    }
  }

  &.light {
    background: ${COLORS.whiteTransparent};

    h3 {
      background: -webkit-linear-gradient(270deg, #010103 0%, rgba(1, 1, 3, 0.49) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span.desc {
      color: ${COLORS.black};
    }

    button.light {
      font-weight: 800;
    }
  }

  @media screen and (max-width: ${global.mobileSize}){
    h3 {
      font-size: 26px;
    }

    button {
      img {
        width: 100px;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 40px;
    }

    span.desc {
      font-size: 21px;
      line-height: 29px;
      color: ${COLORS.white};
    }

    .bold {
      font-weight: 800;
    }
  }
`;
