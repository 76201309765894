import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { swaggerData } from '@swagger/ApisData';
import { APIS, ABOUT } from './utils/urls';
import { localStorageWorker } from './utils/buirg';
import ApiPages from './Pages/ApiPages';
import ThemeProvider from './Context/ThemeContext';
import { ToApisProvider } from './Context/ToApisContext';
import Header from './components/Header';
import About from './Pages/About';
import Footer from './components/Footer';
import Slider from './components/Slider';
import ThemeModal from './components/ThemeModal';
import {
  AppContainer,
  AppWrapper
} from './styles/app.style';

function App() {
  /**
   * State for Hide and Show Header
   */
  const [showHeader, setShowHeader] = useState(true);

  /**
   * State for theme
   */
  const [theme, setTheme] = useState('dark');

  /**
   * Theme change handler
   */
  const handleChangeTheme = () => {
    setTheme((state) => {
      if(state === 'light') {
        localStorageWorker.storeData('theme', 'dark');
        return 'dark';
      }
        localStorageWorker.storeData('theme', 'light');
        return 'light';
    });
  }

  /**
   * Get theme to localStorage
   */
  useEffect(() => {
    const mode = localStorageWorker.getData('theme');
    if(!mode) return false;

    setTheme(mode);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToApisProvider>
        <AppContainer className={`app ${theme} ${showHeader ? '' : 'hide-header'}`}>
          <Router>
            {showHeader && (
            <Header
              theme={theme}
              handleChangeTheme={handleChangeTheme}
            />
          )}
            <AppWrapper className={`wrapper ${showHeader ? '' : 'hide-header'}`}>
              <Switch>
                <Route
                  exact
                  path='/'
                  component={Slider}
                />
                <Route
                  exact
                  path={ABOUT}
                  component={About}
                />
                {swaggerData.map((item) => (
                  <Route
                    key={`${item.title}-${item.for}`}
                    exact
                    path={`${APIS}/${item.title}`}
                    component={() => (
                      <ApiPages
                        swaggerJson={item.swagger}
                        swaggerLight={item.swaggerLight}
                        swaggerDark={item.swaggerDark}
                        title={item.title}
                        theme={theme}
                        hideHeader={setShowHeader}
                        handleChangeTheme={handleChangeTheme}
                      />
                  )}
                  />
                ))}
                <Route render={() => <Redirect to={{pathname: "/"}} />} />
              </Switch>
            </AppWrapper>
            <Footer />
          </Router>
          <ThemeModal setTheme={setTheme} />
        </AppContainer>
      </ToApisProvider>
    </ThemeProvider>
  );
}

export default App;
