import payoutsLight from '../assets/images/payoutsLight.svg';
import payoutsDark from '../assets/images/payoutsDark.svg';

/* eslint-disable max-len */
export const swaggerPayments = (mode) => ({
  "openapi": "3.0.0",
  "info": {
    "title": "sep31 transfer",
    "description": `\n## Prerequisites\n1. Create a Stellar Address and Seed ([details here >>](https://developers.stellar.org/docs/tutorials/create-account/#create-a-keypair)).\n2. Establish [trustlines](https://developers.stellar.org/docs/issuing-assets/anatomy-of-an-asset/#trustlines):\n\n      Env        | Token  | Asset issuer\n      ---        | ---    | ---\n      Production | EURT   | GAP5LETOV6YIE62YAM56STDANPRDO7ZFDBGSNHJQIYGGKSMOZAHOOS2S\n      Staging    | PURPLE | GBT4VVTDPCNA45MNWX5G6LUTLIEENSTUHDVXO2AQHAZ24KUZUPLPGJZH\n  \n3. Provide your Stellar Address to Tempo. It is an authorization key which allows Tempo to issue for you: \n    - \`base_url\` - host address for endpoints\n    - \`sep0031_app_guid\` - unique identifier of your application\n\n5. Be capable to issue a JWT authentication token according to [SEP-0010](https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0010.md)\n\n## Workflow\nTo make a payment follow these steps:\n1. Call \`GET /info\` endpoint to retrieve required fields for a transaction. Please consult Appendix for the full list of available methods, their names and GUIDs.\n2. Call \`GET /customer\` to check KYC status for a sender and a recipient (according to SEP-0012).\n3. Call \`PUT /customer\` to create a customer and upload their KYC info.\n4. Call \`POST /transactions\` to create a transaction and retrieve a link onto a Payment Form.\n5. Call \`GET /transaction\` to check status of a transaction and missing documents.\n![Image](${mode ? payoutsDark : payoutsLight})\n`,
    "contact": {
      "url": "https://eurt.eu/",
      "email": "integration@eurt.eu"
    },
    "version": "0.6"
  },
  "servers": [
    {
      "url": "https://{base_url}/payments/{sep0031_app_guid}",
      "variables": {
        "sep0031_app_guid": {
          "description": "Unique application identifier",
          "default": "3f535456-eda0-4692-8307-0cd5b5615dc3"
        },
        "base_url": {
          "description": "Server host",
          "default": "stage-tempopayments-api.rock-west.net/api/v1"
        }
      }
    }
  ],
  "security": [
    {
      "bearerAuth": []
    }
  ],
  "tags": [
    {
      "name": "Payments",
      "description": "\nFull protocol description: [SEP-0031 Stellar document](https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0031.md).\n"
    }
  ],
  "paths": {
    "/sep0031/info": {
      "get": {
        "tags": [
          "Payments"
        ],
        "summary": "Info about available methods",
        "description": "Get fields and fees for a sep31 transfer",
        "operationId": "InfoGet",
        "responses": {
          "200": {
            "description": "Returns required fields for a transaction (used later at `POST \\transactions`).",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "receive": {
                      "type": "object",
                      "properties": {
                        "receive": {
                          "type": "object",
                          "properties": {
                            "<method_code>": {
                              "type": "object",
                              "properties": {
                                "enabled": {
                                  "type": "boolean"
                                },
                                "max_amount": {
                                  "type": "number"
                                },
                                "sender_sep12_type": {
                                  "type": "string"
                                },
                                "receiver_sep12_type": {
                                  "type": "string"
                                },
                                "fields": {
                                  "type": "object",
                                  "properties": {
                                    "<method_guid>": {
                                      "type": "object",
                                      "properties": {
                                        "deposit": {
                                          "type": "object",
                                          "properties": {
                                            "min_amount": {
                                              "type": "number"
                                            },
                                            "max_amount": {
                                              "type": "number"
                                            },
                                            "fee_percent": {
                                              "type": "number"
                                            },
                                            "fields": {
                                              "title": "list of required inputs",
                                              "type": "object"
                                            }
                                          }
                                        },
                                        "remit": {
                                          "type": "object",
                                          "properties": {
                                            "min_amount": {
                                              "type": "number"
                                            },
                                            "max_amount": {
                                              "type": "number"
                                            },
                                            "fields": {
                                              "type": "object",
                                              "properties": {
                                                "<field_name>": {
                                                  "type": "object",
                                                  "properties": {
                                                    "description": {
                                                      "type": "string"
                                                    },
                                                    "optional": {
                                                      "type": "boolean"
                                                    },
                                                    "choices": {
                                                      "type": "array",
                                                      "items": {
                                                        "type": "string"
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    },
                                    "transaction": {
                                      "type": "object",
                                      "properties": {
                                        "deposit_method": {
                                          "type": "object",
                                          "properties": {
                                            "description": {
                                              "type": "string"
                                            },
                                            "choices": {
                                              "type": "array",
                                              "items": {
                                                "type": "string",
                                                "format": "method_guid"
                                              }
                                            }
                                          }
                                        },
                                        "remit_method": {
                                          "type": "object",
                                          "properties": {
                                            "description": {
                                              "type": "string"
                                            },
                                            "choices": {
                                              "type": "array",
                                              "items": {
                                                "type": "string",
                                                "format": "method_guid"
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "examples": {
                  "standard": {
                    "value": {
                      "receive": {
                        "PURPLE": {
                          "enabled": true,
                          "max_amount": 5000,
                          "sender_sep12_type": "sep31-sender",
                          "receiver_sep12_type": "sep31-receiver",
                          "fields": {
                            "4516b1f0-f10b-466a-9e31-6f8324279cea": {
                              "deposit": {
                                "fee_percent": 1,
                                "fields": {}
                              }
                            },
                            "6eba46d5-ae91-4370-b11d-a89c5a2254e5": {
                              "remit": {
                                "max_amount": 5000,
                                "fields": {
                                  "amount": {
                                    "description": "amount you plan to remit"
                                  }
                                }
                              }
                            },
                            "947e24f0-9246-4ac0-ae3a-ff669c0af111": {
                              "deposit": {
                                "fields": {
                                  "billing_first_name": {
                                    "description": "Customer's first name"
                                  },
                                  "billing_last_name": {
                                    "description": "Customer's last name"
                                  },
                                  "redirect_url": {
                                    "optional": true,
                                    "description": "url where a customer will be redirected with parameters status=[success|fail], transaction_id, method, amount, currency"
                                  },
                                  "amount": {
                                    "description": "amount you plan to deposit"
                                  },
                                  "currency": {
                                    "optional": true,
                                    "description": "deposit currency"
                                  },
                                  "billing_country": {
                                    "description": "Country ISO Alpha-2 code",
                                    "choices": [
                                      "AD",
                                      "AT",
                                      "BE",
                                      "CY",
                                      "DE",
                                      "EE",
                                      "ES",
                                      "FI",
                                      "FR",
                                      "GB",
                                      "GR",
                                      "IE",
                                      "IT",
                                      "LT",
                                      "LU",
                                      "LV",
                                      "MC",
                                      "ME",
                                      "MT",
                                      "NL",
                                      "PT",
                                      "RU",
                                      "SE",
                                      "SI",
                                      "SK",
                                      "SM",
                                      "VA"
                                    ]
                                  }
                                }
                              }
                            },
                            "c5ad5236-4019-4ca3-a4fc-b20432ca8f5d": {
                              "deposit": {
                                "min_amount": 10,
                                "max_amount": 1500,
                                "fields": {
                                  "amount": {
                                    "description": "amount in EUR that you plan to deposit"
                                  },
                                  "email": {
                                    "description": "email"
                                  },
                                  "redirect_url": {
                                    "optional": true,
                                    "description": "url where a customer will be redirected with parameters status=[success|fail], transaction_id, method, amount, currency"
                                  }
                                }
                              },
                              "remit": {
                                "min_amount": 1,
                                "max_amount": 5000,
                                "fields": {
                                  "amount": {
                                    "description": "amount in EUR that you plan to withdraw"
                                  },
                                  "card_number": {
                                    "description": "card number where to make a payment to"
                                  }
                                }
                              }
                            },
                            "transaction": {
                              "deposit_method": {
                                "description": "Method of deposit to make",
                                "choices": [
                                  "947e24f0-9246-4ac0-ae3a-ff669c0af111",
                                  "c5ad5236-4019-4ca3-a4fc-b20432ca8f5d",
                                  "4516b1f0-f10b-466a-9e31-6f8324279cea"
                                ]
                              },
                              "remit_method": {
                                "description": "Method of remit to make",
                                "choices": [
                                  "c5ad5236-4019-4ca3-a4fc-b20432ca8f5d",
                                  "6eba46d5-ae91-4370-b11d-a89c5a2254e5"
                                ]
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "security": [],
        "x-codeSamples": [
          {
            "lang": "Python 2.7",
            "source": "source code here"
          },
          {
            "lang": "Go",
            "source": "Go code right here"
          }
        ]
      }
    },
    "/sep0031/transactions": {
      "post": {
        "tags": [
          "Payments"
        ],
        "summary": "Create a sep31 transfer",
        "description": "Returns ID of the created transaction (used later at `GET \\transactions`).    \n",
        "operationId": "TransactionsPost",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "amount": {
                    "type": "number",
                    "format": "decimal"
                  },
                  "asset_code": {
                    "type": "string"
                  },
                  "sender_id": {
                    "type": "string",
                    "format": "guid"
                  },
                  "receiver_id": {
                    "type": "string",
                    "format": "guid"
                  },
                  "fields": {
                    "type": "object",
                    "properties": {
                      "deposit_method": {
                        "type": "string",
                        "format": "guid"
                      },
                      "remit_method": {
                        "type": "string",
                        "format": "guid"
                      },
                      "<method_guid>": {
                        "type": "object",
                        "properties": {
                          "deposit": {
                            "type": "object",
                            "properties": {
                              "amount": {
                                "type": "number",
                                "format": "decimal"
                              },
                              "<field_name>": {
                                "type": "string"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "example": {
                  "amount": 53,
                  "asset_code": "PURPLE",
                  "sender_id": "ed42ad80-005f-4ef7-a902-b609c4b55411",
                  "receiver_id": "ed42ad80-005f-4ef7-a902-b609c4b55411",
                  "fields": {
                    "deposit_method": "947e24f0-9246-4ac0-ae3a-ff669c0af111",
                    "remit_method": "37a2109e-2c05-484a-bc13-ed40a61d1432",
                    "947e24f0-9246-4ac0-ae3a-ff669c0af111": {
                      "deposit": {
                        "amount": 53,
                        "email": "test@payment.aa"
                      },
                      "remit": {
                        "amount": 11,
                        "billing_country": "CY",
                        "email": "example@wallet.com"
                      }
                    },
                    "37a2109e-2c05-484a-bc13-ed40a61d1432": {
                      "amount": 53
                    }
                  }
                }
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "Transaction created. Check its status with `GET /transactions`",
            "content": {
              "application/json": {
                "schema": {
                  "required": [
                    "how",
                    "id",
                    "stellar_account_id",
                    "stellar_memo",
                    "stellar_memo_type"
                  ],
                  "type": "object",
                  "properties": {
                    "id": {
                      "type": "string",
                      "format": "guid"
                    },
                    "stellar_account_id": {
                      "title": "Tempo's stellar account awaiting tokens to proceed the transfer.",
                      "type": "string"
                    },
                    "stellar_memo_type": {
                      "type": "string",
                      "enum": [
                        "text"
                      ]
                    },
                    "stellar_memo": {
                      "type": "string"
                    },
                    "extra_info": {
                      "type": "object",
                      "properties": {
                        "amount": {
                          "type": "string"
                        },
                        "bank_name": {
                          "type": "string"
                        },
                        "beneficiary": {
                          "type": "string"
                        },
                        "beneficiary_address": {
                          "type": "string"
                        },
                        "bic": {
                          "type": "string"
                        },
                        "currency": {
                          "type": "string"
                        },
                        "external_transaction_id": {
                          "type": "string",
                          "format": "guid"
                        },
                        "iban": {
                          "type": "string"
                        },
                        "reference_id": {
                          "type": "string"
                        },
                        "transaction_id": {
                          "type": "string",
                          "format": "guid"
                        }
                      }
                    },
                    "how": {
                      "type": "string"
                    }
                  }
                },
                "examples": {
                  "SWIFT": {
                    "value": {
                      "id": "6238dd28-a47a-470e-9cee-7f547730d6b1",
                      "stellar_account_id": "GBUQO65XW7TDXIHND7MFNIUZU6UNBA5XN27ODFDXVOLZYOXQSQUTPVHW",
                      "stellar_memo_type": "text",
                      "stellar_memo": "psp:2852",
                      "extra_info": {
                        "amount": "53",
                        "bank_name": "BRED BAGNOLET",
                        "beneficiary": "TEMPO FRANCE",
                        "beneficiary_address": "89 BOULEVARD DE MAGENTA 75010 PARIS",
                        "bic": "BREDFRPPXXX",
                        "currency": "EUR",
                        "external_transaction_id": "5b22d1ed-b789-48e0-9d45-9d2f116f0159",
                        "iban": "FR76 1010 7002 3000 5220 3386 959",
                        "reference_id": "swift_101",
                        "transaction_id": "5b22d1ed-b789-48e0-9d45-9d2f116f0159"
                      },
                      "how": "SWIFT"
                    }
                  },
                  "payment form": {
                    "value": {
                      "id": "2840",
                      "stellar_account_id": "GBUQO65XW7TDXIHND7MFNIUZU6UNBA5XN27ODFDXVOLZYOXQSQUTPVHW",
                      "stellar_memo_type": "text",
                      "stellar_memo": "psp:2840",
                      "extra_info": null,
                      "how": "https://stage-stform-paymentdriver.tempo.eu.com/ interactive?jwt=<JWT_KEY>"
                    }
                  }
                }
              }
            }
          },
          "400": {
            "description": "Insufficient data for a transaction.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "status": {
                      "type": "string",
                      "enum": [
                        "transaction_info_needed",
                        "customer_info_needed"
                      ]
                    },
                    "fields": {
                      "type": "object"
                    }
                  }
                },
                "example": {
                  "status": "customer_info_needed",
                  "fields": [
                    "email",
                    "billing_country"
                  ]
                }
              }
            }
          },
          "401": {
            "description": "X-Authorization header is blank, missing or invalid.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string"
                    }
                  }
                },
                "example": {
                  "error": "can't authenticate account against sep-0010 auth token"
                }
              }
            }
          },
          "403": {
            "description": "JWT is invalid.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string"
                    }
                  }
                },
                "example": {
                  "error": "invalid sep-0010 jwt, error: token is expired"
                }
              }
            }
          }
        }
      }
    },
    "/sep0031/transactions/{id}": {
      "get": {
        "tags": [
          "Payments"
        ],
        "summary": "Check transaction",
        "description": "Check transaction status and get insufficient KYC fields if needed.",
        "parameters": [
          {
            "name": "id",
            "in": "path",
            "required": true,
            "style": "simple",
            "explode": false,
            "schema": {
              "type": "string",
              "format": "guid"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "Transaction was found.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "id": {
                      "type": "string"
                    },
                    "status": {
                      "type": "string",
                      "enum": [
                        "pending_sender",
                        "pending_stellar",
                        "pending_customer_info_update",
                        "pending_transaction_info_update",
                        "pending_receiver",
                        "pending_external",
                        "completed",
                        "error"
                      ]
                    },
                    "amount_in": {
                      "type": "string",
                      "format": "decimal"
                    },
                    "amount_out": {
                      "type": "string",
                      "format": "decimal"
                    },
                    "amount_fee": {
                      "type": "string",
                      "format": "decimal"
                    },
                    "stellar_account_id": {
                      "type": "string"
                    },
                    "stellar_memo_type": {
                      "type": "string",
                      "enum": [
                        "text"
                      ]
                    },
                    "stellar_memo": {
                      "type": "string"
                    },
                    "started_at": {
                      "type": "string",
                      "format": "timestamp"
                    },
                    "stellar_transaction_id": {
                      "type": "string",
                      "format": "hex"
                    },
                    "external_transaction_id": {
                      "type": "string"
                    }
                  }
                },
                "example": {
                  "id": "3bf73ba8-8a48-41b7-99da-b3d70ad959c0",
                  "status": "completed",
                  "amount_in": "53.0000000",
                  "amount_out": "51.6066212",
                  "amount_fee": "0.0000000",
                  "stellar_account_id": "GBUQO65XW7TDXIHND7MFNIUZU6UNBA5XN27ODFDXVOLZYOXQSQUTPVHW",
                  "stellar_memo_type": "text",
                  "stellar_memo": "psp:2850",
                  "started_at": "2020-10-06 14:50:40.508155 +0000 UTC",
                  "stellar_transaction_id": "18b6a4b464b061aefd949c00645deb6732b18ed3acb723d3ba5ac6ba00465d94",
                  "external_transaction_id": "18b6a4b464b061aefd949c00645deb6732b18ed3acb723d3ba5ac6ba00465d94"
                }
              }
            }
          },
          "401": {
            "description": "X-Authorization header is blank, missing or invalid.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string"
                    }
                  }
                },
                "example": {
                  "error": "can't authenticate account against sep-0010 auth token"
                }
              }
            }
          },
          "403": {
            "description": "JWT is invalid.",
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "error": {
                      "type": "string"
                    }
                  }
                },
                "example": {
                  "error": "invalid sep-0010 jwt, error: token is expired"
                }
              }
            }
          }
        },
        "security": [
          {
            "bearerAuth": []
          }
        ]
      }
    }
  },
  "components": {
    "responses": {
      "Expired": {
        "description": "JWT is invalid.",
        "content": {
          "application/json": {
            "schema": {
              "type": "object",
              "properties": {
                "error": {
                  "type": "string"
                }
              }
            },
            "example": {
              "error": "invalid sep-0010 jwt, error: token is expired"
            }
          }
        }
      },
      "Unauthorized": {
        "description": "X-Authorization header is blank, missing or invalid.",
        "content": {
          "application/json": {
            "schema": {
              "type": "object",
              "properties": {
                "error": {
                  "type": "string"
                }
              }
            },
            "example": {
              "error": "can't authenticate account against sep-0010 auth token"
            }
          }
        }
      }
    },
    "securitySchemes": {
      "bearerAuth": {
        "type": "http",
        "scheme": "bearer",
        "bearerFormat": "JWT"
      }
    }
  }
})
