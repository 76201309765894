/**
 * @file
 * Contains ThemeProvider component.
 *  
 * InputElement contains next attributes:
 * # theme {string} is can be dark or light.
 * # children react nodes
 */
import React from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = React.createContext('dark');

const ThemeProvider = ({ theme, children }) => (
  <ThemeContext.Provider value={theme}>
    {children}
  </ThemeContext.Provider>
)

ThemeProvider.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']).isRequired,
  children: PropTypes.node.isRequired
}

export default ThemeProvider;
