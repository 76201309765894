/**
 * @file
 * Contains Header component.
 *  
 * InputElement contains next attributes:
 *  # isAnimated {boolean} can be true or false.
 *  # content {array} array with container
 *  # img {string} img for container
 *  # title {string} title for page
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@context/ThemeContext';
import { ReactComponent as CheckSvg } from '@assets/images/icons/check.svg';
import PaymentSolutionPng from '@assets/images/PaymentSolution.png';
import PayoutSolutionPng from '@assets/images/PayoutSolution.png';
import StablecoinPng from '@assets/images/Stablecoin.png';

import {
  Container,
  LeftContainer,
  RightContent,
  ItemContainer,
  Title
} from './style';

const mainPageContent = [
  {
    title: "Payment Solution",
    content: [
      {
        title: "It's Convenient.",
        id: 'Its Convenient.',
        desc: 'Increase customer satisfaction through various payment methods.',
      },
      {
        title: "It's Cost-efficient.",
        id: "It's Cost-efficient.",
        desc: 'Our transfer commissions are lower than tradition payment providers.',
      },
      {
        title: "It's Super Fast.",
        id: 'Super Fast',
        desc: 'Payment gateway with global payment methods in just a single technology layer.',
      }
    ],
    img: PaymentSolutionPng
  },
  {
    title: "Payout Solution",
    content: [
      {
        title: "We are flexible",
        id: "We are flexible",
        desc: `Management of whole EURT cycle is in our control 
        whether you’re looking to offer settlement solutions, 
        cross-border payments or card withdrawals.`,
      },
      {
        title: "Various payout destinations",
        id: "Various payout destinations",
        desc: `Meet your local customers’ expectations and 
        offer them the most relevant payout destinations for their needs.`,
      },
      {
        title: "API integration",
        id: 'API integration',
        desc: `With EURT payout solution you can payout funds to your 
        global customers and partners with a single API-request.`,
      }
    ],
    img: PayoutSolutionPng
  },
  {
    title: "Stablecoin",
    content: [
      {
        title: "It’s a cutting-edge technology.",
        id: "It’s a cutting-edge technology.",
        desc: `The EURT platform is built on the Stellar blockchain, 
        enabling It's security and transparency.`,
      },
      {
        title: "It’s secured.",
        id: "It’s secured.",
        desc: `Stellar's blockchain-enabled technology delivers world-class security 
        international compliance standards and regulations.`,
      },
      {
        title: "It’s 100% Backed.",
        id: 'Super Fast',
        desc: `EURT is 100% backed by our reserves, which include traditional 
        currency and cash equivalents.
          1 EURT is always valued by us at 1 EUR.`,
      }
    ],
    img: StablecoinPng
  }
]

const MainSamePages = ({ isAnimated }) => {
  const theme = useContext(ThemeContext);
  return(
    mainPageContent.map((item) => (
      <Container key={item.title}>
        <LeftContainer animate={isAnimated}>
          <Title theme={theme}>{item.title}</Title>
          <div>
            {item.content.map((contentItem) => (
              <div key={contentItem.id} className="content">
                <CheckSvg />
                <ItemContainer
                  key={contentItem.title}
                >
                  <h3>{contentItem.title}</h3>
                  <p>{contentItem.desc}</p>
                </ItemContainer>
              </div>
            )
          )}
          </div>
        </LeftContainer>
        <RightContent animate={isAnimated}>
          <div>
            <img src={item.img} alt={item.title} />
          </div>
        </RightContent>
      </Container>
    ))
  )
}

MainSamePages.propTypes = {
	isAnimated: PropTypes.bool.isRequired
}

export default MainSamePages;
