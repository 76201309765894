/**
 * @file
 * Contains ToApisProvider component.
 *  
 * InputElement contains next attributes:
 * # children react nodes
 */
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const ToApisContext = createContext(null);

const ToApisProvider = ({children}) => {
  /**
   * state for apis page
   */
  const [toApis, setToApis] = useState(false);

  return (
    <ToApisContext.Provider value={[toApis, setToApis]}>
      {children}
    </ToApisContext.Provider>
  );
}

ToApisProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { ToApisContext, ToApisProvider };
