/**
 * @file
 * Contains LightDarkButton component.
 *  
 * InputElement contains next attributes:
 *  # theme {string} is can be dark or light.
 *  # handleChangeTheme {function} change theme handler
 */
import React from 'react';
import PropTypes from 'prop-types';
import DarkPng from '@assets/images/icons/dark.png';
import LightPng from '@assets/images/icons/light.png';
import { Button } from './style';

const LightDarkButton = ({handleChangeTheme, theme, addText}) => (
  <Button
    className={theme}
    onClick={handleChangeTheme}
    type="button"
  >
    {theme === 'light' ?
      <img src={DarkPng} alt="dark mode" />
    : (
      <img
        src={LightPng}
        alt="light mode"
      />
     )}
    {addText && (
    <span>
      {theme}
      {' '}
      Mode
    </span>
)}
  </Button>
);

LightDarkButton.defaultProps = {
  addText: false
}

LightDarkButton.propTypes = {
	theme: PropTypes.oneOf(['dark', 'light']).isRequired,
  handleChangeTheme: PropTypes.func.isRequired,
  addText: PropTypes.bool,
}

export default LightDarkButton;
