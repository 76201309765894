import styled from 'styled-components';
import HudAnimationPng from '@assets/images/hudanimation.png';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;

  @media screen and (max-width: ${global.mobileSize}){
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  h1, div {
    transition: all 0.5s ease;
    transform: ${({animate}) => animate ? `translate(-1000%, 0)` : 'translate(0, 0)'};

    &:nth-child(1) {
      transition: all 0.7s ease;
      transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
    }
    &:nth-child(2) {
      transition: all 0.9s ease;
      transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
    }
    &:nth-child(3) {
      transition: all 1.2s ease;
      transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
    }
    &:nth-child(4) {
      transition: all 1.4s ease;
      transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
    }
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: 43px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    transition: 1s ease;
    background: ${({active}) => active ? COLORS.whiteBlue : COLORS.white};
    box-shadow: 0px 0px 10px #3886BD;
    border-radius: 48px;
  }

  h3 {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    max-width: 512px;
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  margin: 70px 0 55px 0;
  ${({theme}) => theme === 'dark' ? global.TitleDark : global.TitleLight};
`;

export const RightContent = styled.div`
  display: ${({animate}) => animate ? 'none' : 'flex'};
  position: relative;
  width: 50%;
  height: 100%;
  padding: 95px 0 0 26px;

  @keyframes roll {
  0% {
    transform: rotate(0);
    }
  100% {
    transform: rotate(720deg);
    }
  }

  div {
    position: absolute;
    top: 54%;
    right: 54px;
    transform: translate(0,-50%) scale(0);
    visibility: hidden;
    opacity: 0;
    transition: all 1.1s ease-in;

    &::after {
      content: url(${HudAnimationPng});
      position: absolute;
      top: -163px;
      right: -140px;
      transform-origin: 460.5px 451.5px;
    }

    @media screen and (max-width: ${global.webSize}){
      right: -7px;

      .speed {
        right: -10px;
      }
    }
  }

  ${({name}) => `
      div.${name} {
        opacity: 1;
        visibility: visible;
        transform: translate(0,-50%) scale(1.014);

        &::after {
        animation-name: roll;
        animation-duration: 4s;
        animation-delay: 1.3s;
        }
      }

      .speed {
        right: 51px;
      }
    `}

  @media screen and (min-width: ${global.tabletSize}) and (max-width:${global.webSize}) {
    .speed {
      right: -10px;
    }
    @media screen and (max-height: 790px) {
      ${({name}) => `
        div.${name} {
          transform: translate(0,-50%) scale(0.8);
          right: -11px;
        }
      `}
      .speed {
        right: -14px;
      }
    }
  }

  p {
    transition: all 1.2s ease;
    transform: ${({animate}) => animate ? `translate(0, 1000%)` : 'translate(0, 0)'};
    margin: -40px auto 0 auto;
    max-width: 520px;
    font-size: 20px;
    text-align: center;
  }
`;

export const MobileContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;

  h1 {
    margin-bottom: 25px;
    transition: all 0.4s ease;
    transform: ${({animate}) => animate ? `translate(-1000%, 0)` : 'translate(0, 0)'};
  }

  .title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 7px;
    transition: all 0.8s ease;
    transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
  }

  .rectangle {
    width: 104px;
    height: 4px;
    margin-bottom: 30px;
    background: ${COLORS.whiteBlue};
    border-radius: 48px;
    box-shadow: 0px 0px 10px ${COLORS.whiteBlue};
    transition: all 0.8s ease;
    transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
  }

  .desc {
    max-width: 350px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px;
    z-index: 1;
    transition: all 1.2s ease;
    transform: ${({animate}) => animate ? `translate(-900%, 0)` : 'translate(0, 0)'};
  }

  .img-container {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transform: translate(0%, 0%) scale(0.9);
    img {
      position: absolute;
      left: 50%;
      top: 58.5%;
      visibility: hidden;
      opacity: 0;
      transition: all 1.5s ease-in;
      transform: translate(-50%,-32%) scale(0);

      ${({animate}) => !animate ? `
        opacity: 1;
        visibility: visible;
        transition-delay: 1s;
        transform: translate(-50%,-32%) scale(0.92);
      ` : `
        transition: all 0s;
      `}
    }
  }

  @media screen and (max-width: ${global.mobileSize}){
    .desc {
     font-size: 22px;
    }
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    .img-container {

      img {
        top: 55.3%;
        transform: translate(-50%,-32%) scale(0);
        ${({animate}) => !animate && `
          transform: translate(-50%,-32%) scale(0.615);
        `}
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeM}){
    h1 {
      margin-top: 50px;
    }

    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 16px;
    }

    .img-container {

      img {
        transform: translate(-50%,-32%) scale(0);
        ${({animate}) => !animate && `
          transform: translate(-50%,-32%) scale(0.615);
        `}
      }
    }

    @media screen and (min-height: 800px){
      .img-container {

        img {
          top: 58%;
        }
      }
    }

    @media screen and (max-height: 680px){
      .img-container {

        img {
          top: 54.2%;
        }
      }
    }

    @media screen and (max-height: 610px){
      .img-container {

        img {
          top: 52.3%;
        }
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    h1 {
      margin-top: 30px;
      font-size: 24px;
    }

    .title {
      font-size: 16px;
    }

    .desc {
      margin-bottom: 18px;
      font-size: 12px;
    }

    .img-container {

      img {
        top: 56.5%;
        transform: translate(-50%,-32%) scale(0);
        ${({animate}) => !animate && `
          transform: translate(-50%,-32%) scale(0.513);
        `}
      }
    }

    @media screen and (max-height: 667px) {
      .img-container {

        img {
          transform: translate(-50%,-34.6%) scale(0);
          ${({animate}) => !animate && `
            transform: translate(-50%,-34.6%) scale(0.513);
          `}
        }
      }
    }

    @media screen and (max-height: 568px) {
      .img-container {

        img {
          transform: translate(-50%,-36.6%) scale(0);
          ${({animate}) => !animate && `
            transform: translate(-50%,-36.6%) scale(0.513);
          `}
        }
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeXS}){
    .rectangle {
      margin-bottom: 20px;
    }

    .img-container {
      img {
        top: 56.5%;
        transform: translate(-50%,-37%) scale(0);
        ${({animate}) => !animate && `
          transform: translate(-50%,-37%) scale(0.413);
        `}
      }
    }
  }
`;
