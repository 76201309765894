/**
 * @file
 * Contains WhyEurt component.
 *  
 * InputElement contains next attributes:
 *  # isAnimated {boolean}
 *  # theme {string} can be light or dark
 *  # size {array} contain width and height
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TransparentPng from '@assets/images/transparent.png';
import EfficientPng from '@assets/images/Efficient.png';
import SpeedPng from '@assets/images/Speed.png';
import Volatility from '@assets/images/Volatility.png';
import {
  Container,
  LeftContainer,
  RightContent,
  ItemContainer,
  Title,
  MobileContainer
} from './style';

const content = [
  {
    title: 'Transparent',
    id: 'transparent',
    desc: 'Track each transaction status through an open worldwide spread network.',
    img: TransparentPng,
    imgDesc: 'Increase customer satisfaction with seamless digital payment experiences'
  },
  {
    title: 'Cost Efficient',
    id: 'efficient',
    desc: "EURT's consensus mechanism allows significantly to reduce costs.",
    img: EfficientPng,
    imgDesc: `Reduce customer acquisition and transaction costs. Utilise lower electronic 
    money account barriers.`
  },
  {
    title: 'Speed',
    id: 'speed',
    desc: 'It takes only 3 to 5 seconds of confirmation time to proceed a transaction.',
    img: SpeedPng,
    imgDesc: 'Increase customer satisfaction with seamless digital payment experiences'
  },
  {
    title: 'Hedge against volatility',
    id: 'volatility',
    desc: `EURT's solution provides a hedging method for
    cryptocurrency traders in Euro.`,
    img: Volatility,
    imgDesc: `Increase a customer satisfaction by presenting a Safe, 
    Scalable and Audited stablecoin for their needs`
  },
]

const WhyEurt = ({ isAnimated, theme, size }) => {
  /**
   * state for get hovering item
   */
  const [onHoverItem, setOnHoverItem] = useState('');

  /**
   * set hover item index
   * @param {number} index 
   */
  const handleOnHover = (index) => {
    setOnHoverItem(index);
  }

  /**
   * add first item when component is showing
   */
  useEffect(() => {
    setOnHoverItem(!isAnimated ? 'transparent' : '');
  },[isAnimated])

  return(
      size[0] > 768 ? (
        <Container>
          <LeftContainer animate={isAnimated}>
            <Title theme={theme}>Why EURT ?</Title>
            <div>
              {content.map((item) => (
                <ItemContainer
                  key={item.title}
                  active={item.id === onHoverItem}
                  onMouseOver={() => handleOnHover(item.id)}
                >
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </ItemContainer>
            )
          )}
            </div>
          </LeftContainer>
          <RightContent animate={isAnimated} name={onHoverItem}>
            {content.map((item) => (
              <div key={item.id} className={item.id}>
                <img src={item.img} alt={item.title} />
                <p>{item.imgDesc}</p>
              </div>
            ))}
          </RightContent>
        </Container>
      ) : content.map((item) => (
        <MobileContainer animate={isAnimated} key={item.title}>
          <Title theme={theme}>Why EURT ?</Title>
          <h3 className="title">{item.title}</h3>
          <div className="rectangle" />
          <p className="desc">{item.desc}</p>
          <p className="desc">{item.imgDesc}</p>
          <div className="img-container" key={item.id}>
            <img src={item.img} alt={item.title} />
          </div>
        </MobileContainer>
      ))
  )
}

WhyEurt.propTypes = {
  isAnimated:  PropTypes.bool.isRequired,
  theme:  PropTypes.oneOf(['dark', 'light']).isRequired,
  size: PropTypes.array.isRequired
}

export default WhyEurt;
