import styled from 'styled-components';
import contactUsLightLayer from '@assets/images/contact-us-light.png';
import contactUsDarkLayer from '@assets/images/contact-us-dark.png';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const HeaderContainer = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: ${global.maxWidth};
	width: 100%;
	height: 10vh;
	padding: 60px 0 0 0;
	font-weight: 800;
	z-index: 20;

	@media screen and (max-width: ${global.webSize}){
		max-width: 1170px;
  }

	@media screen and (max-width: 1170px){
		padding: 40px 20px 0 20px;
	}

	@media screen and (max-width: ${global.tabletSize}){
		justify-content: center;

		& > div {
			width: auto;
		}
	}

	@media screen and (max-width: ${global.mobileSizeXS}){
		padding-top: 30px;
	}
`;

export const LogoContainer = styled.div`
	display: ${(props) => props.disabled ? "none" : "block"};
	width: 30%;
	font-style: italic;
	font-size: 30px;
	line-height: 20px;

	a {
		color: ${COLORS.whiteBlue} !important;
	}
	img {
		margin-right: 10px;
	}

	@media screen and (max-width: ${global.mobileSizeS}){
    img {
			width: 92px;
		}
	}
`;

export const Menu = styled.nav`
	position: relative;
	width: 70%;
	ul {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		li {
			margin-left: 80px;
			cursor: pointer;

			&.active {
				a {
					color: ${COLORS.whiteBlue};
					font-weight: 800;
				}

				button {
					color: ${COLORS.whiteBlue};
					font-weight: 800;
				}

				&.up {
					z-index: 11;
				}
			}

			button, a {
				color: ${COLORS.white};
				font-weight: 400;
				outline: none;
			}

			&.contact {
				margin-left: 118px;
			}
		}

		&::before {
			content: url(${contactUsDarkLayer});
			position: absolute;
			top: -50px;
			right: -120px;
			z-index: -1;
		}
	}

	&.light {
		li {
			button, a {
				color: ${COLORS.textLightMode};
			}
		}

		ul {
			&::before {
				content: url(${contactUsLightLayer});
			}
		}
	}

	@media screen and (max-width: ${global.tabletSize}){
		display: none;
	}
`;

export const MobileMenuContainer = styled.div`
	display: none;
	position: absolute;
	right: 25px;
	top: 50px;

	ul {
		li {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 23px;
			margin-bottom: 77px;
			font-weight: 400;

			&.apis {
				margin-left: 15px;
			}

			&.contact {
				margin-left: 25px;
			}

			svg {
				margin-right: 30px;
			}

			button {
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
			}

			&.active {
				.defaultColor {
					color: ${COLORS.whiteBlue};
				}
			}
		}

		button {
			margin-left: 20px;

			img {
				margin-right: 10px;
			}
		}
	}

	.modal-container {
		flex-direction: column;
	}


	.option {
		position: absolute;
		top: 50px;
		left: 60px;
		font-size: 16px;
	}

	@media screen and (max-width: ${global.tabletSize}){
		display: block;
	}

	@media screen and (max-width: ${global.mobileSizeM}){
		top: 36px;
		.modal-container {
			& > button {
				top: 20px;

				&.option {
					top: 25px;
				}
			}
		}
		.option {
			left: 20px;
		}
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		top: 35px;
	}

	@media screen and (max-width: ${global.mobileSizeXS}){
		top: 23px;
	}
`;
