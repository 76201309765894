/**
 * @file
 * Contains LightDarkButton component.
 *  
 * InputElement contains next attributes:
 * # theme {string} is can be dark or light.
 * # Component {react element} should be component with swagger.json.
 * # handleChangeTheme {function} change theme handler
 * # hideHeader {boolean} hide page header
 * # title {string} page title
 */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ToApisContext } from '@context/ToApisContext';
import { RedocStandalone } from 'redoc';
import { ThemeContext } from '@context/ThemeContext';
import LightDarkButton from '@components/LightDarkButton';
import {
	SwaggerContainer,
  TitleContainer,
  ThemeOptions
} from './style';

const ApiPages = (props) => {
  const {
    theme,
    handleChangeTheme,
    hideHeader,
    title,
    swaggerJson,
    swaggerLight,
    swaggerDark
   } = props;

  const history = useHistory();
  const themeMode = useContext(ThemeContext);

  /**
  * scroll to apis page handler
  */
  const [, setToApis] = useContext(ToApisContext);

  /**
   * Change page to Apis handler
   */
	const handleBackApi = () => {
    history.push('/');
    setTimeout(() => setToApis(true), 0);
  }

  /**
   * Hide and Show Header in Apis page
   */
  useEffect(() => {
    hideHeader(false);

    return () => hideHeader(true);
  }, []);

  return(
    <SwaggerContainer className={themeMode}>
      <TitleContainer className="title-container">
        <button
          type="button"
          onClick={handleBackApi}
        >
          &#10092;
          <span className="option">
            <span className="hide-in-mobile option">Back to</span>
            {' '}
            APIs
          </span>
        </button>
        <h1>{title}</h1>
        <LightDarkButton theme={theme} handleChangeTheme={handleChangeTheme} />
      </TitleContainer>
      <RedocStandalone
        spec={swaggerJson || (theme === 'light' ? swaggerLight : swaggerDark)}
        options={{
        hideDownloadButton: true,
        theme: {...ThemeOptions},
	    	}}
      />
    </SwaggerContainer>
  )
}

ApiPages.defaultProps = {
  theme: 'light',
  swaggerJson: null,
  swaggerLight: null,
  swaggerDark: null
}

ApiPages.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  title: PropTypes.string.isRequired,
  handleChangeTheme: PropTypes.func.isRequired,
  swaggerJson: PropTypes.any,
  swaggerLight: PropTypes.any,
  swaggerDark: PropTypes.any,
  hideHeader: PropTypes.func.isRequired,
}

export default ApiPages;
