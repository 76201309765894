/**
 * Data for Apis page and swagger component
 */
import PaymentSvg from '@assets/images/Payments.svg';
import KYCSvg from '@assets/images/icons/KYC.svg';
import WalletsSvg from '@assets/images/icons/wallet.svg';
import { swaggerPayments } from './swagger-sep-0031';
import swaggerKYC from './swagger-sep-0012.json';
import swaggerWallets from './swagger-sep-0006.json';

/**
 * Apis page content
 */
export const swaggerData = [
	{
		img: PaymentSvg,
		title: 'Payments',
		for: '(corporates)',
		points: [
			'Execute and track transfers',
			'Set up a variety of payment methods',
			'Manage operational checks'
    ],
		swaggerDark: swaggerPayments(true),
		swaggerLight: swaggerPayments(false)
	},
	{
		img: WalletsSvg,
		title: 'Wallets',
		for: '(individuals)',
		points: [
			'Manage end users accounts ',
			'Run deposits and withdrawals',
			'Supply multi-currency operations'
    ],
    swagger: swaggerWallets
	},
	{
		img: KYCSvg,
		title: 'KYC',
		for: '(end users)',
		points: [
			'Work with end customers profiles',
			'Perform and manage KYCs',
			'Supervise passing documents'
    ],
    swagger: swaggerKYC
	}
];
