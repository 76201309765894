/**
 * @file
 * Contains Contact Us form.
 * # open {boolean} show form
 * # closeModal {func} close modal
 * # hideGoBack {boll} hide and show go back button
 */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@context/ThemeContext';
import { ReactComponent as ErrorSvg } from '@assets/images/icons/error.svg';
import { ReactComponent as SuccessSvg } from '@assets/images/icons/success.svg';
import { ReactComponent as ContactUsSvg } from '@assets/images/icons/contact-us.svg';
import { useMailchimp, Status } from './mailchimpFormHook';
import { Container, MessageContainer } from './style';

const ContactUsForm = ({ open, closeModal }) => {
  const theme = useContext(ThemeContext);

  /**
   * state for message handling
   */
  const [openMessage, setOpenMessage] = useState(false);

  /**
   * state for missing field 
   */
  const [missFiled, setMissFiled] = useState(false);

  /**
   * state for form inputs data
   */
  const [form, setForm] = useState({name: '', email: '', phone: ''});

  /**
   * use hook for mailchimp 
   */
  const {
    subscribe,
    status
  } = useMailchimp(`
  https://rock-west.us7.list-manage.com/subscribe/post?u=5ec43890075a092c6566ad51a
  &amp;id=4249ababac`);

  /**
   * input change handler
   * @param {event} event 
   */
  const handleInputChange = event => {
    const { target } = event
    const { name } = target
    const { value } = target

    setForm(state=> ({
      ...state,
      [name]: value,
    }));
  }

  /**
   * button submit handler
   */
  const handleSubmit = () => {

    if (form.phone === '' || form.email === '' || form.phone.length < 7) return setMissFiled(true);

    subscribe({
      FNAME: form.name,
      PHONE: form.phone,
      EMAIL: form.email,
    }, () => {
      setOpenMessage(true);
      setMissFiled(false);
    })
  }

  useEffect(() => {
    setForm({
      name: '',
      email: '',
      phone: ''
    });
    setMissFiled(false);
    setOpenMessage(false);
  }, [open, closeModal])

  useEffect(() => {
    setTimeout(() => {
      if (Status.success === status){
        closeModal();
        setOpenMessage(false);
      } else if (Status.error === status) {
        setOpenMessage(false);
      }
    }, 4000)
  }, [status])

  if (Status.success === status && openMessage) {
    return (
      <MessageContainer className={theme} open={open}>
        <SuccessSvg />
        <h3>Thank You!</h3>
        <p>
          Thank you.
          <br />
          The form is submitted successfully.
        </p>
      </MessageContainer>
    )
  }

  return(
    <Container open={open}>
      {!openMessage && (
      <>
        <button
          onClick={closeModal}
          type="button"
          className="option"
        >
          &#10092; Go Back
        </button>
        <p className="form-title">
          <ContactUsSvg />
          {' '}
          Contact Us
        </p>
      </>
      )}
      {status === Status.error && openMessage ? (
        <MessageContainer className={theme} open={open}>
          <ErrorSvg />
          <h3>Oops!</h3>
          <p>
            Something went wrong.
            <br />
            {' '}
            Please try again.
          </p>
        </MessageContainer>
      ): (
        <form>
          <label htmlFor="name">
            <p>{form.name && "Name Surname"}</p>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Enter Your Name"
              value={form.name}
              onChange={handleInputChange}
            />
          </label>
          <label
            className={`${missFiled && (!form.phone || form.phone.length < 7) ? 'error' : ''}`}
            htmlFor="phone"
          >
            <p>{form.phone && "Phone number"}</p>
            <input
              id="phone"
              name="phone"
              type="number"
              autoComplete="true"
              placeholder="Phone number"
              value={form.phone}
              onChange={handleInputChange}
            />
          </label>
          <label className={`${missFiled && !form.email ? 'error' : ''}`} htmlFor="email">
            <p>{form.email && "E-mail"}</p>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
              value={form.email}
              onChange={handleInputChange}
            />
          </label>
          <button type="button" onClick={handleSubmit}>Send</button>
        </form>
      )}
    </Container>
)};

ContactUsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default ContactUsForm;
