import styled from 'styled-components';
import * as global from '@styles/global.style';
import { COLORS } from '../../styles/colors.style';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: left;
  ${({theme}) => theme === 'dark' ? global.TitleDark : global.TitleLight};
  margin: 75px 0 0 0;

  @media screen and (max-width: ${global.mobileSize}){
    text-align: center;
    margin-top: 47px;
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    margin-top: 20px;
  }
`;

export const SvgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    width: 132px;
    height: 42px;
    margin: 0 80px 75px  0;
  }

  img {
    height: 32px;
    margin: 0 80px 75px  0;

    &.hitbtc {
      height: 45px;
    }

    &.network {
      height: 50px;
    }
  }

  @media screen and (max-width: ${global.webSize}){
    svg {
      width: 130px;
      height: 36px;
      margin: 0 40px 75px  0;
    }

    img {
      height: 30px;
      margin: 0 50px 75px  0;

    }
  }

  &.light {
    svg {
      path {
        fill: ${COLORS.textLightMode};
      }
    }
  }

  @media screen and (max-width: ${global.tabletSize}){
    padding: 0 25px;

    svg {
      height: 38px;
      margin: 0 48px 50px 0;
    }

    img {
      height: 28px;
      margin: 0 48px 50px  0;
    }
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    svg {
      width: 110px;
      height: 30px;
      margin: 0 10px 25px 0;
    }

    img {
      height: 22px;
      margin: 0 10px 25px 0;

      &.hitbtc {
        height: 38px;
      }

      &.network {
        height: 39px;
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeM}){
    @media screen and (min-height: 800px){
      svg {
        width: 110px;
        height: 30px;
        margin: 0 10px 50px 0;
      }

      img {
        height: 22px;
        margin: 0 10px 50px 0;
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    svg {
      width: 97px;
      height: 24px;
      margin: 0 5px 25px 0;
    }

    img {
      width: 97px;
      height: 18px;
      margin: 0 5px 25px 0;

      &.hitbtc {
        height: 41px;
      }

      &.network {
        height: 42px;
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeXS}){
    svg {
      width: 80px;
      height: 24px;
      margin: 0 5px 25px 0;
    }

    img {
      width: 80px;
      height: 15px;
      margin: 0 5px 25px 0;

      &.hitbtc {
        height: 21px;
        width: 70px;
      }

      &.network {
        height: 28px;
        width: 71px;
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 5px 10px 5px;
  font-size: 14px;
  border-top: 1px solid ${COLORS.white};
  color: ${COLORS.white};

  &.light {
    border-color: ${COLORS.textLightMode};
    color: ${COLORS.textLightMode};

    svg {
      path {
        fill: ${COLORS.textLightMode};
      }
    }
  }

  div:first-child {
    height: 25px;
  }

  svg {
    margin-right: 25px;
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    align-items: center;
    padding: 11px 5px 20px 5px;
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    padding: 11px 5px 17px 5px;
    svg {
      margin-right: 5px;
    }
  }
`;
