import styled from 'styled-components';
import * as global from '@styles/global.style';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${global.mobileSize}){
    flex-direction: column;
    position: relative;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  h1 {
    transition: all 0.5s ease;
    transform: ${({animate}) => animate ? `translate(-300%, 0)` : 'translate(0, 0)'};
  }

  .content {
    display: flex;
    &:nth-child(1) {
      transition: all 0.7s ease;
      transform: ${({animate}) => animate ? `translate(-400%, 0)` : 'translate(0, 0)'};
    }

    &:nth-child(2) {
      transition: all 0.9s ease;
      transform: ${({animate}) => animate ? `translate(-500%, 0)` : 'translate(0, 0)'};
    }

    &:nth-child(3) {
      transition: all 1.1s ease;
      transform: ${({animate}) => animate ? `translate(-600%, 0)` : 'translate(0, 0)'};
    }

    svg {
      transition: all 1.3s ease;
      transform: ${({animate}) => animate ? `translate(-1100%, 0)` : 'translate(0, 0)'};
    }
  }

  @media screen and (max-width: ${global.mobileSize}){
    padding: 0 20px;
    h1 {
      margin: 50px 0 34px 0;
      text-align: center;
    }

    .content {
      & > div {
        margin-bottom: 15px;
      }
    }

    & > div {
      z-index: 1;
    }
  }

  @media screen and (max-width: ${global.mobileSizeXS}){
    h1 {
      margin-top: 20px;
    }
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: 46px;

  h3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    max-width: 512px;
    font-size: 16px;
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const Title = styled.h1`
  margin-bottom: 100px;
  ${({theme}) => theme === 'dark' ? global.TitleDark : global.TitleLight};

  @media screen and (max-width: ${global.mobileSizeL}){
    font-size: 30px;
  }

  @media screen and (max-width: ${global.mobileSizeXS}){
    font-size: 20px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
  padding: 95px 0 0 26px;

  div {
    position: absolute;
    top: 53.2%;
    right: 53px;
    transform: translate(0,-50%) scale(0);
    visibility: hidden;
    opacity: 0;
    transition: all 0.8s ease-in;

    ${({animate}) => !animate ? `
      opacity: 1;
      visibility: visible;
      transform: translate(0,-50%) scale(1.01);
    ` : `
      transition: all 0s;
    `}

    @media screen and (max-width: ${global.webSize}) {
      right: -5px;
    }

    @media screen and (min-width: ${global.tabletSize}) and (max-width:${global.webSize}) 
    and (max-height: 790px){
      ${({animate}) => !animate && `
        transform: translate(0,-50%) scale(0.8);
        right: -11px;
      `};
    }
  }

  @media screen and (max-width: ${global.mobileSize}){
    position: absolute;
    width: 100%;
    padding: 0;
    left: 50%;
    top: 66%;
    transform: translate(-50%, -50%) scale(1);

    div {
      top: 52.4%;
      right: 63px;
      transform: translate(0,-50%) scale(0);
      ${({animate}) => !animate && `
        transform: translate(0,-50%) scale(0.83);
      `}
    }
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%) scale(0.6);

    div {
      top: 65.3%;
      right: 50%;
      transform: translate(50%,-49%) scale(0);
      ${({animate}) => !animate && `
        transform: translate(50%,-49%) scale(0.92);
      `}
    }
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    div {
      transform: translate(50%,-49%) scale(0);
      ${({animate}) => !animate && `
        transform: translate(50%,-49%) scale(0.77);
      `}
    }
  }

  @media screen and (max-width: ${global.mobileSizeXS}){
    div {
      transform: translate(50%,-50%) scale(0);
      ${({animate}) => !animate && `
        transform: translate(50%,-50%) scale(0.62);
      `}
    }
  }
`;
