/**
 * @file
 * Contains Header component.
 *  
 * InputElement contains next attributes:
 *  # theme {string} is can be dark or light.
 *  # handleChangeTheme {function} change theme handler
 */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory, } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ABOUT } from '@utils/urls';
import { ToApisContext } from '@context/ToApisContext';
import LightDarkButton from '@components/LightDarkButton';
import RightSideModal from '@components/RightSideModal';
import ContactUsForm from '@components/ContactUsForm';
import Logo from '@assets/images/logo.png';
import { ReactComponent as LogoSvg } from '@assets/images/logo.svg';
import MobileMenu from './MobileMenu';
import { HeaderContainer, LogoContainer, Menu } from './style';

const Header = ({ handleChangeTheme, theme}) => {
  const history = useHistory();

  /**
   * scroll to apis page handler
   */
  const [toApis, setToApis] = useContext(ToApisContext);

  /**
   * State for add active url
   */
  const [activePath, setActivePath] = useState(history.location.pathname);

  /**
   * State for open modal
   */
  const [openModal, setOpenModal] = useState(false);

  /**
   * Open and close modal
   */
  const toggleModal = () => {
    setOpenModal((state) => !state);
  }

  /**
   * scroll slider to Apis page
   */
  const goToApis = async () => {
    await setToApis(true);
    if(history.location.pathname !== '/'){
      history.push('/', { notMain: true });
    }
  }

  /**
   * Start listen history change
   */
	useEffect(() => {
		history.listen(() => {
			setActivePath(history.location.pathname)
		})
	}, [])

	return (
  <HeaderContainer>
    <LogoContainer>
      <Link to="/">
        {
          navigator.userAgent.toLowerCase().indexOf('safari') !== -1 ?
            <img src={Logo} alt="Eurt logo" />
          :
            <LogoSvg />
        }
      </Link>
    </LogoContainer>
    <Menu className={theme}>
      <ul>
        <li className={activePath === ABOUT ? 'active' : ''}>
          <Link to={ABOUT}>
            About EURT
          </Link>
        </li>
        <li
          className={toApis ? 'active' : ''}
        >
          <button type="button" onClick={goToApis}>
            APIs
          </button>
        </li>
        <li className={`${openModal ? 'active up' : ''} contact`}>
          <button data-touch="none" type="button" onClick={toggleModal}>Contact Us</button>
        </li>
        <li className={`${openModal ? 'active up' : ''}`}>
          <LightDarkButton data-touch="none" handleChangeTheme={handleChangeTheme} theme={theme} />
        </li>
      </ul>
    </Menu>
    <MobileMenu
      activePath={activePath}
      toApis={toApis}
      setToApis={setToApis}
      handleChangeTheme={handleChangeTheme}
      theme={theme}
    />
    <RightSideModal closeModal={toggleModal} open={openModal}>
      <ContactUsForm open closeModal={toggleModal} />
    </RightSideModal>
  </HeaderContainer>
	)
};

Header.propTypes = {
	theme: PropTypes.string.isRequired,
  handleChangeTheme: PropTypes.func.isRequired
}

export default Header;
