/**
 * @file
 * Contains Partners component.
 *  
 * InputElement contains next attributes:
 *  # theme {string} is can be dark or light.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StellarSvg } from '@assets/images/partners/stellar.svg';
import { ReactComponent as IbmSvg } from '@assets/images/partners/ibm.svg';
import { ReactComponent as OkexSvg } from '@assets/images/partners/okex.svg';
import { ReactComponent as AtaixSvg } from '@assets/images/partners/ataix.svg';
import { ReactComponent as CexIoSvg } from '@assets/images/partners/CEX.IO.svg';
import { ReactComponent as WoortonSvg } from '@assets/images/partners/woorton.svg';
import { ReactComponent as JstCapitalSvg } from '@assets/images/partners/jst-capital.svg';
import { ReactComponent as CoinsquareSvg } from '@assets/images/partners/coinsquare.svg';
import { ReactComponent as StrongholdSvg } from '@assets/images/partners/stronghold.svg';
import { ReactComponent as AlipaySvg } from '@assets/images/partners/Alipay.svg';
import { ReactComponent as NovattiSvg } from '@assets/images/partners/novatti.svg';
import { ReactComponent as GopaxSvg } from '@assets/images/partners/gopax.svg';
import { ReactComponent as FacebookSvg } from '@assets/images/partners/Facebook.svg';
import { ReactComponent as TwitterSvg } from '@assets/images/partners/Twitter.svg';
import { ReactComponent as InstagramSvg } from '@assets/images/partners/Instagram.svg';
import BanqueDarkPng from '@assets/images/partners/banque-populaire-dark.png';
import HitbtcDarkPng from '@assets/images/partners/hitbtc-dark.png';
import BitbondDarkPng from '@assets/images/partners/bitbond-dark.png';
import MatchmoveDarkPng from '@assets/images/partners/matchmove-dark.png';
import BinanceDarkPng from '@assets/images/partners/Binance-dark.png';
import KyberNetworkDarkPng from '@assets/images/partners/kyber-network-dark.png';
import BanqueLightPng from '@assets/images/partners/banque-populaire-light.png';
import HitbtcLightPng from '@assets/images/partners/hitbtc-light.png';
import BitbondLightPng from '@assets/images/partners/bitbond-light.png';
import MatchmoveLightPng from '@assets/images/partners/matchmove-light.png';
import BinanceLightPng from '@assets/images/partners/Binance-light.png';
import KyberNetworkLightPng from '@assets/images/partners/kyber-network-light.png';
import {
  Container,
  Title,
  SvgContainer,
  Footer
} from './style';

const Partners = ({ theme }) => (
  <Container>
    <Title theme={theme}>Partners</Title>
    <SvgContainer className={theme}>
      <StellarSvg />
      <IbmSvg />
      <OkexSvg />
      <AtaixSvg />
      <CexIoSvg />
      <WoortonSvg />
      <AlipaySvg />
      <JstCapitalSvg />
      <CoinsquareSvg />
      <StrongholdSvg />
      <NovattiSvg />
      <GopaxSvg />
      {theme === 'dark' ? (
        <>
          <img className="hitbtc" src={HitbtcDarkPng} alt="hitbtc" />
          <img className="bitbond" src={BitbondDarkPng} alt="bit bond" />
          <img className="matchmove" src={MatchmoveDarkPng} alt="match move" />
          <img className="binance" src={BinanceDarkPng} alt="binance" />
          <img className="network" src={KyberNetworkDarkPng} alt="kyber network" />
          <img className="ban" src={BanqueDarkPng} alt="ban que" />
        </>
      ) : (
        <>
          <img className="hitbtc" src={HitbtcLightPng} alt="hitbtc" />
          <img className="bitbond" src={BitbondLightPng} alt="bit bond" />
          <img className="matchmove" src={MatchmoveLightPng} alt="match move" />
          <img className="binance" src={BinanceLightPng} alt="binance" />
          <img className="network" src={KyberNetworkLightPng} alt="kyber network" />
          <img className="ban" src={BanqueLightPng} alt="ban que" />
        </>
    )}
    </SvgContainer>
    <Footer className={theme}>
      <div>
        <FacebookSvg />
        <TwitterSvg />
        <InstagramSvg />
      </div>
      <div>
        {`© ${new Date().getFullYear()} All Rights Reserved.`}
      </div>
    </Footer>
  </Container>
)

Partners.propTypes = {
	theme:  PropTypes.oneOf(['dark', 'light']).isRequired,
}

export default Partners;
