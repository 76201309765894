import styled from 'styled-components';

export const Button = styled.button`
  img {
    width: 64px;
  }

  span {
    font-size: 23px;
    text-transform: capitalize;
  }
`;
