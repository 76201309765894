/**
 * @file
 * Contains LightDarkButton component.
 *  
 * InputElement contains next attributes:
 *  # theme {string} is can be dark or light.
 *  # handleChangeTheme {function} change theme handler
 *  # apiActive {boolean} add active apis field
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import RightSideModal from '@components/RightSideModal';
import LightDarkButton from '@components/LightDarkButton';
import ContactUsForm from '@components/ContactUsForm';
import { ABOUT } from '@utils/urls';
import { ReactComponent as MobileMenuSvg } from '@assets/images/icons/mobile-menu.svg';
import { ReactComponent as ApisMobileSvg } from '@assets/images/icons/apis-mobile.svg';
import { ReactComponent as ContactUsSvg } from '@assets/images/icons/contact-us.svg';
import { ReactComponent as LogoMobileSvg } from '@assets/images/icons/Eurt-mobileMenu.svg';
import { MobileMenuContainer } from './style';

const MobileMenu = (props) => {
  const {
    handleChangeTheme,
    theme,
    toApis,
    setToApis,
    activePath
  } = props

  const history = useHistory();

  /**
   * stat for Open and close RightSide modal
   */
  const [open, setOpen] = useState(false);

  /**
   * stat for Open and close ContactUs form
   */
  const [openContactUs, setOpenContactUs ] = useState(false);

  /**
   * Toggle to Open and close RightSide modal
   */
  const toggleModal = () => setOpen((state) => !state);

  /**
   * Change page handler and close Modal
   */
  const changePageHandler = (path) => {
    toggleModal();
    history.push(path);
  }

  /**
   * go to apis page and scroll down
   */
  const goToApis = () => {
    setToApis(true);
    toggleModal();
    if(history.location.pathname !== '/'){
      history.push('/', { notMain: true });
    }
  }

  /**
   * Toggle for Open and close ContactUs form
   */
  const toggleContactUsForm = () => {
    setOpenContactUs((state) => !state);
  }

  return(
    <MobileMenuContainer data-touch="none">
      <button data-touch="none" onClick={toggleModal} type="button">
        <MobileMenuSvg />
      </button>
      <RightSideModal data-touch="none" closeModal={toggleModal} showCloseButton open={open}>
        {!openContactUs && (
          <ul data-touch="none">
            <li className={activePath === ABOUT ? 'active' : ''}>
              <button
                className="defaultColor"
                type="button"
                onClick={() => changePageHandler(ABOUT)}
              >
                <LogoMobileSvg />
                About EURT
              </button>
            </li>
            <li className={`${toApis ? 'active' : ''} apis`}>
              <button
                className="defaultColor"
                type="button"
                onClick={goToApis}
              >
                <ApisMobileSvg />
                {' '}
                APIs
              </button>
            </li>
            <li className="contact">
              <button className="defaultColor" type="button" onClick={toggleContactUsForm}>
                <ContactUsSvg />
                {' '}
                Contact Us
              </button>
            </li>
            <LightDarkButton addText handleChangeTheme={handleChangeTheme} theme={theme} />
          </ul>
        )}
        <ContactUsForm closeModal={toggleContactUsForm} open={openContactUs} />
      </RightSideModal>
    </MobileMenuContainer>
  )
};

MobileMenu.propTypes = {
	theme: PropTypes.string.isRequired,
  handleChangeTheme: PropTypes.func.isRequired,
  activePath: PropTypes.string.isRequired,
  toApis: PropTypes.bool.isRequired,
  setToApis: PropTypes.func.isRequired
}

export default MobileMenu;
