/**
 * @file
 * Contains Main component.
 *  
 * InputElement contains next attributes:
 *  # theme {string} is can be dark or light.
 *  # isAnimated {bool}
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ToApisContext } from '@context/ToApisContext';
import { ReactComponent as StellarIconSvg } from '@assets/images/stellar.svg';
import {
  MainContainer,
  MainWrapper,
  Button
} from './style';

const Main = ({ isAnimated, theme }) => {
  /**
   * scroll to apis page handler
   */
  const [, setToApis] = useContext(ToApisContext);

  /**
   * scroll slider to Apis page
   */
  const goToApis = () => {
    setToApis(true);
  }

  return(
    <MainContainer className={theme}>
      <MainWrapper animate={isAnimated}>
        <h2 className="title">Next-Gen ecosystem for financial transactions</h2>
        <p className="logo option">EURT</p>
        <p className="description">
          own stablecoin, equivalent of Euro & flawless payments globally
        </p>
        <Button onClick={goToApis}>Discover opportunities</Button>
        <p className="created-by">
          Powered By
          <StellarIconSvg />
        </p>
      </MainWrapper>
    </MainContainer>
  )
}

Main.propTypes = {
	theme:  PropTypes.oneOf(['dark', 'light']).isRequired,
  isAnimated: PropTypes.bool.isRequired
}

export default Main;
