import styled from 'styled-components';
import * as global from '@styles/global.style';
import { COLORS } from '@styles/colors.style';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .title {
    ${global.TitleDark};
    font-size: 30px !important;
    text-align: center;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .logo {
    font-size: 265px;
    line-height: 240px;
    font-family: 'Avenir-Black' !important;
    background: -webkit-linear-gradient(260deg, #3886BD 0%, rgba(56, 134, 189, 0.38) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    color: #F6F8FA;
    text-align: center;
    margin-bottom: 27px;
  }

  .created-by {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 30px 0 50px 0;

    svg {
      margin-left: 6px;
    }
  }

  &.light {
    .title {
      ${global.TitleLight};
    }

    .description {
      color: ${COLORS.textLightMode};
    }

    .created-by {
      svg {
        path {
          fill: ${COLORS.textLightMode};
        }
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    align-items: flex-start;

    .title {
      margin: 30px 0 10px 0;
    }

    .logo {
      font-size: 140px;
      line-height: 128px;
    }

    .description {
      max-width: 260px;
    }


    @media screen and (min-height: 800px) {
      .title {
        margin-top: 120px;
      }
    }
  }

  @media screen and (max-width: ${global.mobileSizeS}){
    .title {
      margin: 20px 0 ;
      font-size: 20px !important;
    }

    button {
      width: 234px;
    }

    .logo {
      font-size: 120px;
    }
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto;
  width: 100%;
  overflow: hidden;
    .title, .logo, .description, .created-by, button {
      transition: all 1.2s ease;
      transform: ${({animate}) => animate ? `translate(-1000%, 0)` : 'translate(0, 0)'};
    }
`;

export const Button = styled.button`
  ${global.ButtonStyle};
`;
