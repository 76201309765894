import { COLORS } from './colors.style';

export const maxWidth = '1440px';
export const webSize = '1440px';
export const webSizeM = '1360px';
export const webSizeS = '1180px';
export const tabletSize = '1024px';
export const mobileSize = '768px';
export const mobileSizeL = '600px';
export const mobileSizeM = '500px';
export const mobileSizeS = '375px';
export const mobileSizeXS = '320px';

export const ButtonStyle = `
  width: 274px;
  height: 60px;
  font-weight: 800;
  font-size: 18px;
  color: ${COLORS.white};
  background: ${COLORS.whiteBlue};
  border-radius: 81px;
  filter: drop-shadow(0px 10px 20px rgba(17, 84, 255, 0.25));
`;

export const TitleDark = `
  font-size: 36px;
  font-family: 'Avenir-Black' !important;
  background: -webkit-linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.31) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: ${tabletSize}){
    font-size: 30px;
  }
`;

export const TitleLight = `
  font-size: 36px;
  font-family: 'Avenir-Black' !important;
  background: -webkit-linear-gradient(270deg, #010103 0%, rgba(1, 1, 3, 0.49) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: ${tabletSize}){
    font-size: 30px;
  }
`;
