import styled from 'styled-components';
import RightModalBackground from '@assets/images/right-modal-background.svg';
import LightBackground from '@assets/images/right-modal-background-light.svg';
import mobileDarkBg from '@assets/images/mobileRightSideBgDark.png';
import mobileRightSideBgLight from '@assets/images/mobileRightSideBgLight.png';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const Modal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  width: 0%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  transition: width 0.8s ease;

  &.open {
    width: 100%;
    visibility: visible;

    .modal-container {
      height: 100%;
      visibility: visible;
    }
  }


  &.light {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(8px);

    & > div {
      background: url(${LightBackground});
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;
      
      form {
        input {
          color: ${COLORS.black};
          border-color: ${COLORS.black};

          &::placeholder {
            color: ${COLORS.black};
          }
        }
      }

      @media screen and (max-width: ${global.mobileSize}){
        background: url(${mobileRightSideBgLight}) !important;
        background-repeat: no-repeat !important;
        background-position: left;
        background-size: cover !important;
      }
    }
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  width: 100%;
  height: 0%;
  max-width: 45%;
  transition: height 0.8s ease;
  background: url(${RightModalBackground});
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;

  @media screen and (max-width: 1250px){
    max-width: 70%;
  }

  & > button {
    display: ${({show}) => show ? 'block' : 'none'};
    position: absolute;
    right: 36px;
    top: 40px;
    font-size: 25px;
    font-weight: 800;
    color: ${COLORS.whiteBlue};
  }

  @media screen and (max-width: ${global.mobileSize}){
    max-width: 100%;
    background: url(${mobileDarkBg});
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
  }
`;
