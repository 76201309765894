/**
 * @file
 * mailchimp form hook
 */
import { useState, useCallback } from 'react';
import jsonp from 'jsonp';

/**
 * status for request
 */
export const Status = {
	idle: 'IDLE',
	loading: 'LOADING',
	success: 'SUCCESS',
	error: 'ERROR'
}

/**
 * add query in path
 * @param {object} params 
 */
function toQueryString(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

/**
 * make request to mailchimp
 * @param {string} url for mailchimp form
 */
export function useMailchimp(url) {
  /**
   * state for request status
   */
  const [status, setStatus] = useState(Status.idle);

  /**
   * state for request error
   */
  const [error, setError] = useState(null);

  /**
   * state for request value
   */
  const [value, setValue] = useState(null);

  /**
   * send request
   */
  const subscribe = useCallback((data, callback) => {
    const params = toQueryString(data);
    const ajaxURL = url.replace("/post?", "/post-json?");
    const newUrl = `${ajaxURL}&${params}`;

    setError(null);
    setStatus(Status.loading);

    jsonp(newUrl, { param: "c" }, (err, dat) => {
      if (err) {
        setStatus(Status.error);
        setError(err);
      } else if (dat.result !== "success") {
        setStatus(Status.error);
        setError(dat.msg);
      } else {
        setStatus(Status.success);
        setValue(dat.msg);
      }

      callback();
    });
  }, []);

  return { subscribe, status, error, value };
}
