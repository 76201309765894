/**
 * @file
 * Contains Slider component.
 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ScrollDarkSvg } from '@assets/images/icons/Scroll-down-dark.svg';
import { ReactComponent as ScrollLightSvg } from '@assets/images/icons/Scroll-down-light.svg';
import { ThemeContext } from '@context/ThemeContext';
import { useWindowSize } from '@hooks/onResize';
import { ToApisContext } from '@context/ToApisContext';
import Main from '@pages/Main';
import WhyEurt from '@pages/WhyEurt';
import MainSamePages from '@components/MainSamePages';
import Apis from '@pages/Apis';
import Partners from '@pages/Partners';

import {
  SliderContainer,
  SliderWrapper,
  SliderArrow,
  RightScrollBar,
  GlobalStyle
} from './style';

const Slider = () => {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  /**
   * touch handling when start
   */
  const [touchStart, setTouchStart] = useState(0);

  /**
   * touch handling when end
   */
  const [touchEnd, setTouchEnd] = useState(0);

  /**
   * scroll to Api
   */
  const [toApis, setToApis] = useContext(ToApisContext);

  /**
   * state for slider size and count 
   */
  const [slider, setSlider] = useState({
    size: 0,
    count: 1
  });

  /**
   * state for slider item count
   */
  const [sliderItemCount, setSliderItemCount] = useState();

  /**
   * state for handling is slider animating 
   */
  const [isAnimated, setIsAnimated] = useState(false);

  /**
   * ref for slider container
   */
  const scrollContainer = useRef(null);

  /**
   * ref for slider wrapper
   */
  const wrapperContainer = useRef(null);

  /**
   * mobile size hook
   */
  const size = useWindowSize();

  /**
   * add animation and remove it after 1second
   */
  const addAnimationTimer = () => {
    setIsAnimated(true);
    setTimeout(() => setIsAnimated(false), 1100);
  }

  /**
   * function to scroll with arrow
   */
  const ScrollDown = () => {
    const containerHeight = scrollContainer.current.offsetHeight;

    if(slider.count < sliderItemCount) {
      setSlider((state) => (
        {
          ...state,
          size: state.size - containerHeight,
          count: state.count + 1
        }
      ));
    } else {
      setSlider((state) => (
        {
          ...state,
          size: 0,
          count: 1
        }
      ));
    }
    addAnimationTimer();
    setToApis(false);
  };

  /**
   * add onScroll handler and scroll slider
   */
  useEffect(() => {
    let touchstart = false;
    let touchend = false;
    const containerHeight = scrollContainer.current.offsetHeight;

    const handleScroll = (event) => {
      if(isAnimated) return;

      if (event.deltaY < 0) {

        if(slider.size >= 0) return;
        addAnimationTimer();
        setSlider((state) => (
          {
            ...state,
            size: state.size + containerHeight,
            count: state.count - 1
          }
        ));
      } else {
        if(slider.size <= containerHeight * (sliderItemCount - 1) * -1) return;
        addAnimationTimer();
        setSlider((state) => (
          {
            ...state,
            size: state.size - containerHeight,
            count: state.count + 1
          }
        ));
      }

      setToApis(false)
    }

    const handleTouchStart = (e) => {
      if(touchstart) return false;
      setTouchStart(e.targetTouches[0].clientY);
      touchstart = true;
    }

    const handleTouchMove = (e) => {
      if(touchend) return false;
      setTouchEnd(e.targetTouches[0].clientY);
      touchend = true;
    }

    const handleTouchEnd = (e) => {
      if(
        touchend ||
        touchstart ||
        e.target.getAttribute('data-touch') ||
        e.target.parentNode.parentNode.getAttribute('data-touch')
      ) return false;

      if(isAnimated) return;

      if(touchStart < touchEnd) {
        if(slider.size >= 0) return;
          addAnimationTimer();
          return setSlider((state) => (
            {
              ...state,
              size: state.size + containerHeight,
              count: state.count - 1
            }
          ));
      }

      if (touchStart > touchEnd) {
        if(slider.size <= containerHeight * (sliderItemCount - 1) * -1) return;
          addAnimationTimer();
          return setSlider((state) => (
            {
              ...state,
              size: state.size - containerHeight,
              count: state.count + 1
            }
          ));
      }

      touchstart = false;
      touchend = false;
    }

    document.addEventListener('mousewheel', handleScroll);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    return () => {
      // This cleans up the event handler when the component unmount.
      document.removeEventListener('mousewheel', handleScroll);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    }
  }, [isAnimated, slider.size, touchStart, touchEnd])

  /**
   * add slider item count
   */
  useEffect(() => {
    setSliderItemCount(wrapperContainer.current.children.length);
  }, [size[0]]);

  /**
   * scroll slider to Apis page
   */
  useEffect(() => {
    if (toApis && sliderItemCount) {
      const containerHeight = scrollContainer.current.offsetHeight;
      setSlider((state) => (
        {
          ...state,
          size: 0 - (containerHeight * (sliderItemCount - 2)),
          count: sliderItemCount - 1
        }
      ));
    }
  }, [toApis, sliderItemCount])

  /**
   * remove apis page active state in unmount
   */
  useEffect(() => {
    return () =>  setToApis(false);
  }, [])

  /**
   * make Api field active
   */
  useEffect(() => {
    if(slider.count === sliderItemCount - 1) {
      setToApis(true)
    }
  }, [slider.count])

  /**
   * scroll in top of slider when click header
   */
  useEffect(() => {
    history.listen(() => {
      if(history.location.pathname === '/' && !history.location.state) {
        setSlider({
          size: 0,
          count: 1
        })
        setToApis(false);
      }
    })
  }, [])

  /**
   * scroll slider when click scrollbar
   * @param {event} e 
   */
  const scrollInClick = (e) => {
    const y = e.pageY - e.target.offsetTop;

    const clickPosition = Math.ceil(y / 45.7);

    const containerHeight = scrollContainer.current.offsetHeight;
      setSlider((state) => (
        {
          ...state,
          size: -containerHeight * (clickPosition - 1),
          count: clickPosition
        }
      ));
  }

  return(
    <SliderContainer
      animate={slider.count !== 1}
      hideGlobous={slider.count > sliderItemCount - 2}
      ref={scrollContainer}
      theme={theme}
    >
      <GlobalStyle animate={slider.count !== 1} />
      <SliderWrapper ref={wrapperContainer} size={slider.size}>
        <Main theme={theme} isAnimated={isAnimated} />
        <WhyEurt size={size} theme={theme} isAnimated={isAnimated} />
        <MainSamePages isAnimated={isAnimated} />
        <Apis visible={slider.count === sliderItemCount - 1} />
        <Partners theme={theme} />
      </SliderWrapper>
      <SliderArrow itemCount={sliderItemCount} onClick={ScrollDown} count={slider.count}>
        {theme === 'dark' ? <ScrollDarkSvg /> : <ScrollLightSvg />}
        {slider.count === sliderItemCount && <p>Scroll to the main page</p>}
      </SliderArrow>
      <RightScrollBar
        onClick={scrollInClick}
        className={theme}
        itemCount={sliderItemCount}
        count={slider.count}
      />
    </SliderContainer>
  )
};

export default Slider;
