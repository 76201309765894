import styled, { createGlobalStyle } from 'styled-components';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';
import apisBackgroundDark from '@assets/images/apisBackgroundDark.png';
import apisBackgroundLight from '@assets/images/apisBackgroundLight.png';

export const GlobalStyle = createGlobalStyle`
	.app {
		position: relative;
		background-image: ${({visible}) => visible && `url(${apisBackgroundDark}) !important`} ;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover !important;
		-webkit-transition: background-image 2s ease;
  	transition: background-image 2s ease;

		&::before {
			content: '';
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.79) 2.63%, rgba(0, 0, 0, 0.4) 100%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.wrapper {
			background: none !important;
			z-index: 1;
		}

		&.light {
			background-image: ${({visible}) => visible && `url(${apisBackgroundLight}) !important`};
			background-size: cover !important;

			&::before {
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.91) 2.63%,
				rgba(255, 255, 255, 0.12) 100%);
			}
		}
	}

	footer {
		display: none !important;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;

	@media screen and (max-width: ${global.mobileSize}){
		justify-content: flex-start;
		padding: 0;
	}
`;

export const Title = styled.h1`
	width: 100%;
	text-align: left;
	margin: 60px 0;
	${({theme}) => theme === 'dark' ? global.TitleDark : global.TitleLight};

	@media screen and (max-width: ${global.tabletSize}){
		margin: 30px 0;
	}

	@media screen and (max-width: ${global.mobileSize}){
		text-align: center;
	}

	@media screen and (max-width: ${global.mobileSizeM}){
		margin: 50px 0;
		font-size: 26px;

		@media screen and (max-height: 800px) {
			margin: 20px 0;
		} 
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		margin: 10px 0;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

export const OptionContainer = styled.div`
	cursor: pointer;
	display: flex;
	width: 47.5%;
	padding: 46px 30px;
	margin: 0 20px 25px 0;
	transition: 0.5s ease;
	background: rgba(1, 1, 3, 0.4);
	border-radius: 8px;
	backdrop-filter: blur(3px);

	&:hover {
		background: rgba(1, 1, 3, 0.7);
		box-shadow: 0px 0px 40px rgba(56, 134, 189, 0.24);
	}

	&.light {
		background: rgba(255, 255, 255, 0.7);

		.explanation {
			color: ${COLORS.textLightMode};
		}
	}

	@media screen and (max-width: ${global.webSize}){
		margin-bottom: 20px;
	}

	@media screen and (max-width: ${global.tabletSize}){
		width: 100%;
		padding: 25px 30px;
		justify-content: center;
	}

	@media screen and (min-width: ${global.tabletSize}) and (max-width:${global.webSize}) 
    and (max-height: 790px){
    padding: 15px;
		margin-bottom: 10px;
  }

	@media screen and (max-width: ${global.mobileSize}){
		padding: 25px 20px;
		margin: 0 13px 15px 13px;
	}

	@media screen and (max-width: ${global.mobileSizeL}){
		padding: 31px 20px;
		justify-content: flex-start;

		@media screen and (max-height: 800px) {
			padding: 15px 20px;
		} 
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		padding: 10px 10px;
		margin-bottom: 10px;
	}
`;

export const OptionImgContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 158px;
	margin-right: 25px;

	img {
		width: 158px;
		filter: drop-shadow(0px 10px 20px rgba(17, 84, 255, 0.25));
	}

	@media screen and (max-width: ${global.mobileSizeL}){
		margin-right: 15px;
		width: 95px;

		img {
			width: 95px;
		}
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		width: 80px;

		img {
			width: 80px;
		}
	}
`;

export const OptionContent = styled.div`
	.explanation {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 8px;
		color: ${COLORS.white};
		opacity: 0.8;
	}

	@media screen and (max-width: ${global.mobileSizeL}){
		.explanation {
			font-size: 14px;
			line-height: 20px;
		}
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		.explanation {
			line-height: 13px;
		}
	}
`;

export const OptionTitleContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	h2 {
		font-weight: 800;
		font-size: 24px;
		margin-right: 15px;
		line-height: 33px;
	}

	span {
		font-size: 18px;
		line-height: 25px;
		color: ${COLORS.grey};
	}

	@media screen and (max-width: ${global.mobileSizeL}){
		margin-bottom: 11px;
	}

	@media screen and (max-width: ${global.mobileSizeS}){
		h2 {
			font-size: 20px;
			line-height: 23px;
			margin-right: 5px;
		}

		span {
			font-size: 18px;
		}
	}
`;
