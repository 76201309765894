/**
 * @file
 * Contains Contact Us page component.
 * 
 */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '@context/ThemeContext';
import { ToApisContext } from '@context/ToApisContext';
import { ContactUsContainer, TextContent, GlobalStyle } from './style';

const About = () => {
  const history = useHistory();

  const theme = useContext(ThemeContext);

  /**
   * scroll to apis page handler
   */
  const [, setToApis] = useContext(ToApisContext);

  /**
   * change path handler
   */
  const handleGoApis = async () => {
    await setToApis(true);
    history.push('/', { notMain: true });
  }

  return(
    <ContactUsContainer>
      <GlobalStyle />
      <TextContent theme={theme}>
        <h1>About EURT</h1>
        <div>
          <p>
            <span className="bold">Limitless worldwide</span>
            {' '}
            access to EUR
          </p>
          <p>
            <span className="bold">Profound functional</span>
            {' '}
            to expand payment coverage by EURT volume
            {' '}
          </p>
          <p>
            Conversion of assets to
            <span className="bold"> stablecoin plegged to the EUR</span>
          </p>
          <p>
            The benefit for an
            <span className="bold"> on-ramps</span>
            {' '}
            and
            <span className="bold"> off-ramps globally</span>
          </p>
          <button onClick={handleGoApis} type="button">
            Watch APIs
          </button>
        </div>
      </TextContent>
    </ContactUsContainer>
  );
}

export default About;
