export const COLORS = {
  whiteBlue: '#3886BD',
  black: '#010103',
  dark: '#000000',
  darkTransparent: 'rgba(0,0,0,0.7)',
  darkModBackground: '#191919',
  textDarkMode: '#F6F8FA',
  codeBackgroundDark: '#333333',
  codeContentBackgroundDark: '#242424',
  codeBackgroundLight: '#F6F8FA',
  textLightMode: '#434343',
  white: '#FFFFFF',
  whiteTransparent: 'rgba(255,255,255, 0.7)',
  boxShadow: 'rgba(56, 134, 189, 0.1)',
  grey: 'rgba(1, 1, 3, 0.4)',
  greyBlack: '#CFCFCF',
  green: '#17FF12',
  darkGreen: '#637E54',
  blackBlue: '#274153',
  strongBlue: '#1C3D54',
  red: '#990000'
}
