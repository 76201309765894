/**
 * @file
 * Contains Api-s page component.
 * 
 */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '@context/ThemeContext';
import { swaggerData } from '@swagger/ApisData';
import { APIS } from '@utils/urls';
import {
	GlobalStyle,
	Container,
	Title,
	Content,
	OptionContainer,
	OptionImgContainer,
	OptionTitleContainer,
	OptionContent
} from './style';

const Apis = ({ visible }) => {
	const theme = useContext(ThemeContext);
	const history = useHistory();

	/**
	 * Change page handler
	 * @param {string} path 
	 */
	const handleClick = (path) => {
		history.push(path);
	}

	return(
  <Container>
    <GlobalStyle visible={visible} />
    <Title theme={theme}>Developers API</Title>
    <Content>
      {swaggerData.map((item) => (
        <OptionContainer
          type="button"
          onClick={() => handleClick(`${APIS}/${item.title}`)}
          key={`${item.title}${item.for}`}
          className={theme}
        >
          <OptionImgContainer>
            <img src={item.img} alt={item.title} />
          </OptionImgContainer>
          <OptionContent>
            <OptionTitleContainer>
              <h2>{item.title}</h2>
              <span className="api-title-for">{item.for}</span>
            </OptionTitleContainer>
            {item.points.map((point) => <p className="explanation" key={`${point}`}>{point}</p>)}
          </OptionContent>
        </OptionContainer>
			))}
    </Content>
  </Container>
	)
};

export default Apis;
