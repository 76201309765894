/**
 * Object for get,set,remove localStorage item
 */
export const localStorageWorker = {
  storeData (key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  getData (key) {
    const item = localStorage.getItem(key);

    if (!item) {
      return false;
    }

    return JSON.parse(item);
  },
  removeData (key) {
    localStorage.removeItem(key);
  }
}
