import styled from 'styled-components';
import mainBgDark from '@assets/images/mainBgDark.png';
import mainBgLight from '@assets/images/mainBgLight.png';
import { COLORS } from './colors.style';
import * as global from './global.style';

export const AppContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  background-image: url(${mainBgLight});
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(122.84deg, #FFFFFF 1.9%, rgba(255, 255, 255, 0) 27.89%),
linear-gradient(180deg, rgba(255, 255, 255, 0.91) 2.63%, rgba(255, 255, 255, 0.12) 100%);

  }

  &.hide-header {
    background: ${COLORS.white};
    overflow-x: inherit;

    &::before {
      content: none !important;
    }

    .wrapper {
      max-width: 100% !important;
      padding: 0 10px;
      background: ${COLORS.white};
    }
  }

  &.dark {
    background-image: url(${mainBgDark});

    &::before{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.79) 2.63%, rgba(0, 0, 0, 0) 100%);
    }

    &.hide-header {
      background: ${COLORS.darkModBackground};

      .wrapper {
        background: ${COLORS.darkModBackground};
      }
    }
    
    .menu-content {
      background: ${COLORS.darkModBackground};
    }

    h1, h2, h3, p, li, span, h5, th, td, .sc-aemoO, .sc-clsHhM, .dJwTUp, .jCGAbc, .dSnsqz {
      color: ${COLORS.textDarkMode};
    }

    tr {
			background-color: ${COLORS.darkModBackground} !important;
		}

    .api-title-for {
      color: ${COLORS.textDarkMode};
      opacity: 0.4;
    }

    .option {
      color: ${COLORS.whiteBlue};
    }

    footer {
      box-shadow: inset 0px 4px 20px rgba(56, 134, 189, 0.2);
    }

    .defaultColor {
      color: ${COLORS.white};
    }
  }

  &.hide-header {
    height: auto;
  }

  @media screen and (max-width: ${global.mobileSize}) {
    justify-content: flex-start;
    height: ${window.innerHeight}px;

    .hide-in-mobile {
      display: none !important;
    }
  }
`;

export const AppWrapper = styled.div`
  position: relative;
  max-width: ${global.maxWidth};
  width: 100%;
  min-height: 75vh;
  border-radius: 10px;

  &.hide-header {
    max-width: 100%;
    padding: 0 40px;
  }

  @media screen and (max-width: ${global.webSize}){
    padding: 0 20px;
    max-width: 1170px;
  }

  @media screen and (max-width: ${global.mobileSize}){
    height: 100%;

    &.hide-header {
      height: auto;
    }
  }
`;
