import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@context/ThemeContext';
import { localStorageWorker } from '@utils/buirg';
import lightIcon from '@assets/images/icons/modalLight.png';
import darkIcon from '@assets/images/icons/modalDark.png';
import { ModalContent, ButtonContainer, ModalContainer } from './style';

const ThemeModal = ({ setTheme }) => {
  const theme = useContext(ThemeContext);

  /**
   * open and close modal
   */
  const [open, setOpen] = useState(false);

  /**
	 * Toggle Modal open  to close 
	 */
  const toggleOpen = () => {
    setOpen((state) => !state);
  };

  /**
   * Chose mode
   * @param {string} theme 
   */
  const choseTheme = (mode) => {
    setTheme(mode);
    localStorageWorker.storeData('theme', mode);
    toggleOpen();
  }

  /**
   * Close Modal when click outside content
   * @param {event} e event
   */
  const onBlur = (e) => {
    if (!e.target.getAttribute("data-out")) return;
    toggleOpen();
  }

  /**
   * Open modal after 5s
   */
  useEffect(() => {
    if(!localStorageWorker.getData('theme')){
      setTimeout(() => {
        toggleOpen();
      }, 5000);
    }
  }, []);

  return (
    <ModalContainer data-out="out" className={theme} onClick={onBlur} open={open}>
      <ModalContent data-touch="none" className={theme}>
        <h3>Choose Mode</h3>
        <ButtonContainer data-touch="none">
          <button onClick={() => choseTheme('light')} className="light" type="button">
            <img src={lightIcon} alt="light theme icon" />
            <span className="desc">Light Mode</span>
          </button>
          <button onClick={() => choseTheme('dark')} className="dark" type="button">
            <img src={darkIcon} alt="dark theme icon" />
            <span className="desc">Dark Mode</span>
          </button>
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
}

ThemeModal.propTypes = {
  setTheme: PropTypes.func.isRequired,
}

export default ThemeModal;
