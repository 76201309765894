import styled, { createGlobalStyle } from 'styled-components';
import PlanetDarkPng from '@assets/images/planetDark.png';
import PlanetLightPng from '@assets/images/planetLight.png';
import AstronautDarkPng from '@assets/images/astronautDark.png';
import AstronautLightPng from '@assets/images/astronautLight.png';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const GlobalStyle = createGlobalStyle`
  div.wrapper {
    max-width: 100%;
    min-height: 90%;
    height: 90%;

    &::before {
      content: url(${AstronautDarkPng});
      position: absolute;
      transition: all 1.5s ease;

      ${({animate}) => animate ? `
        right: -100%;
      ` : `
        right: -34px;
      `};
      bottom: 0;
      z-index: 3;
      margin: auto 0 auto 0;

      @media screen and (max-width: ${global.webSizeM}){
        ${({animate}) => animate ? `
        right: -100%;
        ` : `
          right: -120px;
        `};
      }

      @media screen and (max-width: ${global.webSizeS}){
        ${({animate}) => animate ? `
        right: -100%;
        ` : `
          right: -200px;
        `};
      }

      @media screen and (max-width: ${global.mobileSize}){
        ${({animate}) => animate ? `
        right: -300%;
        ` : `
          right: -350px;
        `};
      }

      @media screen and (max-width: ${global.mobileSizeL}){
        ${({animate}) => animate ? `
          right: -300%;
        ` : `
          right: -345px;
          top: 165px;
          transform: scale(0.59);
        `};

        @media screen and (min-height: 800px) {
          ${({animate}) => animate ? `
          right: -300%;
        ` : `
          right: -345px;
          top: 130px;
          transform: scale(0.7);
        `};
        }
      }

      @media screen and (max-width: ${global.mobileSizeS}){
        ${({animate}) => animate ? `
        right: -300%;
        ` : `
          right: -375px;
          top: 115px;
          transform: scale(0.57)
        `};
        @media screen and (min-height: 800px) {
          ${({animate}) => animate ? `
          right: -300%;
        ` : `
          right: -345px;
          top: 155px;
          transform: scale(0.55);
        `};
        }
      }
    }

    @media screen and (max-width: ${global.mobileSize}){
      padding: 0;
    }
  }

  header {
    height: 10% !important;
  }

  .light {
    div.wrapper {
      &::before {
      content: url(${AstronautLightPng});
      }
    }
  }

  @media screen and (max-width: 768px) {
    .app {
      overflow: hidden;
    }
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  touch-action: none;

  &::after {
    content: url(${({theme}) => theme === 'dark' ? PlanetDarkPng : PlanetLightPng});
    position: absolute;
    transition: all 1.5s ease;
    opacity: 1;
    visibility: visible;
    padding-right: 100px;
    margin: auto 0 auto 0;
    ${({animate}) => animate ? `
      left: 100%;
      top: 50%;
      transform: translate(-100%, -50%) scale(1.1);
    ` : `
      left: 0;
      top: 0;
    `};

    ${({hideGlobous}) => hideGlobous ? `
        opacity: 0;
        visibility: hidden;
    `: ''}

    @media screen and (min-width: ${global.tabletSize}) and (max-width:${global.webSize}) 
      and (max-height: 790px){
        ${({animate}) => animate && `
        transform: translate(-100%, -50%) scale(0.87);
      `};
    }

    @media screen and (max-width: ${global.mobileSize}){
      padding-right: 0 !important;
      ${({animate}) => animate ? `
      left: 50%;
      top: 66%;
      transform: translate(-50%, -50%) scale(0.9);
    ` : `
      left: -20%;
      top: 0px;
    `};
    }

    @media screen and (max-width: ${global.mobileSizeL}){
      ${({animate}) => animate ? `
      left: 50%;
      top: 68%;
      transform: translate(-50%, -50%) scale(0.6);
    ` : `
      left: -110%;
      top: -50px;
    `};
    }

    @media screen and (max-width: ${global.mobileSizeS}){
      ${({animate}) => animate ? `
      left: 50%;
      top: 68%;
      transform: translate(-50%, -50%) scale(0.5);
    ` : `
      left: -125%;
      top: -50px;
    `};
    }

    @media screen and (max-width: ${global.mobileSizeXS}){
      ${({animate}) => animate ? `
      left: 50%;
      top: 68%;
      transform: translate(-50%, -50%) scale(0.4);
    ` : `
      left: -125%;
      top: -50px;
    `};
    }
  }

  @media screen and (max-width: ${global.webSize}){
    max-width: 1170px;

    &::after {
      padding-right: 44px;
    }
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  transform: ${(props) => props.size ? `translate(0px, ${props.size}px)` : 'translate(0px, 0px)'};
  transition: transform 1.5s ease;
  backface-visibility: hidden;
  touch-action: none;
  transform-style: preserve-3d;

  @media screen and (max-width: ${global.webSize}){
    max-width: 1170px;
    margin: 0 auto;
  }
`;

export const SliderArrow = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: ${(props) => props.count === props.itemCount ? '115px' : '35px'};
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;

  svg {
    transition: 1.5s ease;
    transform: ${(props) => props.count === props.itemCount ? 'rotate(180deg)' : 'rotate(0deg)'}
  }

  @media screen and (max-width: ${global.mobileSizeL}){
    bottom: ${(props) => props.count === props.itemCount ? '68px' : '1%'};
  }
`;

export const RightScrollBar = styled.div`
  cursor: pointer;
  position: fixed;
  right: 55px;
  top: 0;
  bottom: 0;
  z-index: 10;
  margin: auto 0 auto 0;
  width: 3px;
  height: 320px;
  background: ${COLORS.dark};

  &::before {
    z-index: 10;
    content: '0${(props) => props.count}';
    position: absolute;
    top: ${(props) => (320 / props.itemCount) * (props.count - 1)}px;
    right: 0;
    width: 30px;
    height: ${({itemCount}) => 320 / itemCount}px;
    line-height: ${({itemCount}) => 320 / itemCount}px;
    border-right: 3px solid ${COLORS.whiteBlue};
    transition: top 1.5s ease;
    font-weight: 800;
    color: ${COLORS.white};
  }

  &.light {
    &::before {
      color: ${COLORS.textLightMode};
    }
  }

  @media screen and (max-width: ${global.mobileSize}){
    display: none;
  }
`;
