/**
 * @file
 * Contains Header component.
 */
import React from 'react';
import { ReactComponent as StarConnectSvg } from '@assets/images/footer-img.svg';
import {
  FooterContainer
} from './style';

const Footer = () => (
  <FooterContainer>
    <p>© 2020   Tempo All Rights Reserved.</p>
    <StarConnectSvg />
  </FooterContainer>
);

export default Footer;
