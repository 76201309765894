import styled from 'styled-components';
import SearchIconLight from '@assets/images/icons/search-light.svg';
import SearchIconDark from '@assets/images/icons/search-dark.svg';
import { COLORS } from '@styles/colors.style';
import * as global from '@styles/global.style';

export const SwaggerContainer = styled.div`
	.menu-content {
		& > div {
			&:first-child {
				position: relative;
				svg {
					display: none;
				}

				input {
					width: 100%;
					padding: 12px 0 12px 60px;
					margin: 0 0 20px 0;
					font-size: 18px;
					color: ${COLORS.grey};
				}

				&::before {
					content: url(${SearchIconLight});
					position: absolute;
					top: 15px;
					left: 15px;
					width: 27px;
					height: 27px;
				}
			}

			a {
				display: none;
			}
		}

		label[role="menuitem"] {
			padding: 0 10px;
			margin-bottom: 17px;
			align-items: center;
			color: ${COLORS.textLightMode};
			border-left: 2px solid ${COLORS.greyBlack};

			&:hover {
				background: none;
			}
		}

		.active {
			background: none;
			font-weight: 800;
			border-left: 2px solid ${COLORS.whiteBlue} !important;
		}

		div[data-role="search:results"] {
			background: ${COLORS.white};
		}
	}

	.api-content {
		h1 {
			font-size: 24px;
			line-height: 54px;
			border-bottom: 1px solid #e6e6e6;
		}

		h2 {
			font-size: 24px;
			margin-bottom: 10px;
		}

		h3, th, td {
			font-size: 18px;
		}

		div {
			&:first-child {
				padding-top: 0;
			}
		}
	}

	.operation-type {
		width: 44px;
		height: 21px;
		font-size: 12px;
		font-weight: 800;
		line-height: 22px;
		box-shadow: 0px 10px 20px rgba(17, 84, 255, 0.25);
		border-radius: 19px;	
	}

	.http-verb {
    height: 21px;
		font-weight: 800;
		font-size: 12px;
		border-radius: 19px;
		line-height: 19px;
	}

	.iSwWWM, .cNapmU, .chZPxt {
		color: ${COLORS.textLightMode};
		background: ${COLORS.white};

		span {
			color: ${COLORS.textLightMode};
		}
	}

	.ksEsPc {
		&:focus {
			color: ${COLORS.textLightMode};
		}
	}

	.react-tabs__tab-panel {
		button {
			font-size: 14px;
		}

		div {
			div {
				span {
					left: auto;
					right: 0;
					padding-top: 5px;
				}
			}
		}

		div, button, span {
			color: ${COLORS.textLightMode};
		}
	}

	.react-tabs__tab-list {
		margin: 10px 0 0 0;

		li {
			margin: 0 10px 0 0;
			border: none;
			border-radius: 10px 10px 0 0;
		}

		li[aria-selected="true"] {
			background: ${COLORS.white};
		}

		li[aria-selected="false"] {
			background: ${COLORS.codeBackgroundLight};
		}
	}

	.diUYLd {
		top: 10px;
		border-radius: 10px;
	}

	h1, .bsnboG, h3, .idNouf {
		color: ${COLORS.textLightMode};
	}

	polygon {
		fill: ${COLORS.textLightMode};
	}

	.token {
		color: ${COLORS.darkGreen} !important;
	}

	.property, .bsokQw {
		color: ${COLORS.textLightMode};
	}

	&.dark {
		.menu-content {
			& > div {
				&:first-child {
					&::before {
						content: url(${SearchIconDark}) !important;
					}
					input {
						color: ${COLORS.textDarkMode};
					}
				}
			}

			div[data-role="search:results"] {
				background: ${COLORS.darkModBackground};
			}

			div[role="search"] {
				color: ${COLORS.textDarkMode};
			}
    }

		.iYIfuN {
			background: ${COLORS.darkModBackground};
		}

		.bsokQw {
			color: ${COLORS.textDarkMode};
		}
		polygon {
			fill: ${COLORS.textDarkMode};
		}

		.ePSOyg,
		.jxchqj,
		pre,
		.ksEsPc,
		.cQvJVZ,
		.bZjSBm,
		.FfjEt,
		.kfmuDz,
		.cTHFHC,
		.bLSmTI,
		.fdBaum,
		.hKVNfl, .sc-Fyfyc {
			background: ${COLORS.codeBackgroundDark};
		}

		li[aria-selected="true"], .chZPxtб, .itDyqLб, .chZPxt, .itDyqL {
			background: ${COLORS.codeContentBackgroundDark};
		}

		li[aria-selected="false"] {
			background: ${COLORS.white};
		}
		
		.react-tabs__tab-panel {
			background: ${COLORS.codeContentBackgroundDark};

			div {
				background: ${COLORS.codeContentBackgroundDark};
				color: ${COLORS.textDarkMode};
			}

			span, button {
				color: ${COLORS.textDarkMode};
			}
		}

		.react-tabs__tab {
			&[aria-selected="true"] {
				color: ${COLORS.textDarkMode};
			}

			&[aria-selected="false"] {
				color: ${COLORS.textLightMode};
			}
		}

		.collapsible {
			.property {
				color: ${COLORS.textDarkMode} !important;
			}
		}

		.title-container {
			background: ${COLORS.darkModBackground};
		}
	}

	@media screen and (max-width: ${global.mobileSize}) {
		.menu-content + div {
			width: 50px;
			height: 50px;

			svg {
				display: none;
			}

			&::before {
				content: url(${SearchIconLight});
				position: absolute;
				top: 12px;
				left: 14px;
			}
		}

		.menu-content[open] + div {
				&::before {
				content: 'X';
				left: 18px;
				font-size: 20px;
				color: ${COLORS.grey};
			}
		}

		.api-content {
			h1 {
				font-size: 22px;
			}
			h2 {
				font-size: 20px;
			}
		}

		.menu-content {
			width: 100%;
			padding: 0 10px;
		}

		h1 {
			font-size: 26px;
		}

		h2 {
			font-size: 20px;
			margin-top: 20px;
		}

		span, p, th, td {
			font-size: 16px;
		}

		.QpUsp {
			padding: 40px 0;
		}
	}

	@media screen and (max-width: ${global.mobileSizeS}) {
		td {
			span {
				font-size: 14px;
			}
		}
	}
`;

export const TitleContainer = styled.div`
	position: sticky;
	top: -60px;
	z-index: 200;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0 40px 0;
	background: ${COLORS.white};

	h1 {
		font-size: 35px;
		font-weight: 800;
	}

	button {
		color: ${COLORS.whiteBlue};

		span {
			margin-left: 14px;
			letter-spacing: 0.045em;
			font-size: 14px;
			font-weight: 800;
			line-height: 16px;
		}
	}

	@media screen and (max-width: ${global.mobileSize}) {
		top: -30px;
		padding-top: 40px;
		h1 {
			font-size: 26px;
		}

		button {
			padding: 0;
			span {
				margin-left: 5px;
			}
		}
	}
`;

export const ThemeOptions = {
	spacing: {
		unit: 5,
	},
	colors: {
		tonalOffset: 0.2,
		http: {
			get: COLORS.whiteBlue,
			post: COLORS.whiteBlue,
			put: COLORS.whiteBlue,
			options: COLORS.whiteBlue,
			patch: COLORS.whiteBlue,
			delete: COLORS.whiteBlue,
			basic: COLORS.whiteBlue,
			link: COLORS.whiteBlue,
			head: COLORS.whiteBlue,
		},
		gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
		},
		text: {
      primary: COLORS.textLightMode,
    },
	},
	schema: {
    defaultDetailsWidth: '75%',
    labelsTextSize: '0.9em',
    nestingSpacing: '1em',
    nestedBackground: '#fafafa',
  },
	typography: {
    fontSize: '18px',
    lineHeight: '1.5em',
    fontWeightRegular: '400',
    fontWeightBold: '600',
    fontWeightLight: '300',
    smoothing: 'antialiased',
    optimizeSpeed: true,
    headings: {
      fontWeight: '800',
			lineHeight: '1.6em',
			color: COLORS.textLightMode,
		},
		code: {
      fontSize: '14px',
      backgroundColor: 'rgba(38, 50, 56, 0.05)',
      wrap: false,
		},
		links: {
			color: COLORS.whiteBlue,
		}
	},
	sidebar: {
    width: '260px',
    backgroundColor: COLORS.white,
    groupItems: {
			textTransform: 'uppercase',
    },
    arrow: {
			size: '1.5em',
		},
  },
  logo: {
    gutter: '2px',
  },
  rightPanel: {
		backgroundColor: COLORS.codeBackgroundLight,
  },
  codeBlock: {
		fontSize: '12px',
    backgroundColor: COLORS.white,
	}
}
