/**
 * @file
 * Contains RightSide Modal component.
 * # children {node} jsx
 * # open {boolean} open modal
 * # showCloseButton {boolean} show X-button in modal
 * # closeModal {func} close modal
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from '@context/ThemeContext';
import { Modal, ModalContainer } from './style';

const RightSideModal = ({ children, open, showCloseButton, closeModal }) => {
  const theme = useContext(ThemeContext);

  /**
   * Close Modal when click outside content
   * @param {event} e event
   */
  const onBlur = (e) => {
    if (!e.target.getAttribute("data-out")) return;
    closeModal();
  }

  return(
    <Modal data-out="out" onClick={onBlur} className={`${theme} ${open ? 'open' : ''}`}>
      <ModalContainer data-touch="none" className="modal-container" show={showCloseButton}>
        <button data-touch="none" onClick={closeModal} type="button">X</button>
        {children}
      </ModalContainer>
    </Modal>
  )
}

RightSideModal.defaultProps = {
  showCloseButton: false,
  closeModal: null
}

RightSideModal.propTypes = {
	children: PropTypes.node.isRequired,
  showCloseButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func
}

export default RightSideModal;
